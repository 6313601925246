import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { LoginInfo } from "../../common/dataType";
import Header from "./common/header";
import { btnContinueStyle2, btnNomalStyle2, btnProofDocUpload, descriptionSmallText, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import fileUpload from '../../img/file_upload_symbol.png';
import MemberContext from "../context/memberContext";
import { SimpleModal } from "./common/modal";
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Marriage = () => {
    const [marriageExperience, setMarriageExperience] = useState<Boolean>();
    const [haveChild, setHaveChild] = useState<Boolean>();
    const [isDocUploaded, setIsDocUploaded] = useState<boolean>(false);
    const [marriageFiles, setMarriageFiles] = useState<Array<any>>([]);
    const [marriageSrcs, setMarriageSrcs] = useState<Array<string>>([]);
    const [uploadfileNames, setUploadFileNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    useEffect(()=>{
        console.log(" memberInfo >> ", memberInfo);
        if(memberInfo.id == 0){
            navigate('/login');
        }else{
            switch(memberInfo.marriageStatusType){
                case 0:
                    setMarriageExperience(undefined);
                    setHaveChild(undefined);
                    break;
                case 1:
                    setMarriageExperience(false);
                    setHaveChild(false);
                    break;
                case 2:
                    setMarriageExperience(true);
                    setHaveChild(false);
                    break;
                case 3:
                    setMarriageExperience(true);
                    setHaveChild(true);
                    break;
                case 4:
                    setMarriageExperience(false);
                    setHaveChild(true);
                    break;
            }

            setUploadFileNames(memberInfo.marriageProofDocsName);
        }

    },[])


    const goToAcademy = async () => {
        let serverResult = {ok:false, type:'none'};
        if(marriageExperience != null && haveChild != null){
            setLoadingOpen(true);
            let marriageStatus = 0;
            if(marriageExperience && haveChild){
                marriageStatus = 3;  //  유자녀 재혼
            }else if(marriageExperience && !haveChild){
                marriageStatus = 2;  //  무자녀 재혼
            }else if(!marriageExperience && !haveChild){
                marriageStatus = 1;  //  무자녀 싱글 
            }else if(!marriageExperience && haveChild){
                marriageStatus = 4;  //  미혼모, 비혼부(유자녀 싱글) 
            }
            if(marriageFiles.length == 0){
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", {id: memberInfo.id, marriageStatusType:marriageStatus, isMarriageProofed:memberInfo.marriageProofDocsName.length > 0 ? true: false});
                setLoadingOpen(false);
                if(dbUpdate.data.ok){
                    memberInfo.marriageStatusType = marriageStatus;
                    serverResult.ok = true;
                    serverResult.type ='file';
                }else{
                    setModalMessage("결혼 경헙 정보 입력 오류");
                    setOpen(true);
                }
            }else{
                let formData = new FormData();
                let fileNames = [];
                let lastModifieds = "";
                for (let i = 0; i < marriageFiles.length; i++) {
                    console.log("marriageFiles >>>> ",marriageFiles[i]);
                    formData.append("files", marriageFiles[i]);
                    let fileName = "proof/marriageStatus/" + marriageFiles[i].lastModified +"_" +memberInfo.id+"_"+marriageFiles[i].name;  // DB에 들어가는 FileName
                    let trimedFileName = fileName.replace(/ /g, "");
                    fileNames.push(trimedFileName);
                    lastModifieds = lastModifieds + marriageFiles[i].lastModified + "_"+memberInfo.id+"_"+marriageFiles[i].name+"#";
                }
                formData.append("filePath", "proof/marriageStatus/");
                formData.append("lastModifieds", lastModifieds.replace(/ /g,""));
                let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", {id: memberInfo.id ,marriageProofUrl:fileNames, marriageStatusType:marriageStatus, isMarriageProofed:true});
                setLoadingOpen(false);
                if (dbUpdate.data.ok && s3Result.status == 201) {
                    memberInfo.marriageStatusType = marriageStatus;
                    memberInfo.marriageProofDocsName = uploadfileNames 
                    serverResult.ok = true;
                    serverResult.type ='file';
                } else {
                    if(s3Result.status != 201){
                        setModalMessage("파일 업로드 오류");
                    }else if(dbUpdate.data.ok != true){
                        setModalMessage("DB 입력 오류");
                    }
                    setOpen(true);
                    console.log(" error >> ", s3Result.statusText);
                }
            }
        }else{
            setModalMessage("결혼 경험 여부와 자녀 유무 여부를 입력해 주세요.");
            setOpen(true);
        }
        return serverResult;
    }

    const handleUploadDoc = (ev:any) => {
        let docs: Array<any> = ev.target.files;
        console.log(" docs >> ", docs);
        setMarriageFiles(docs);
        let urlDocs: Array<string> = [];
        let fnames: Array<string> = [];
        for (let i = 0; i < docs.length; i++) {
            urlDocs.push(URL.createObjectURL(docs[i]));
            fnames.push(docs[i].name.replaceAll(" ",""));
        }
        console.log(" urlDocs >>> ", urlDocs);
        console.log(" fnames >>> ", fnames);
        setMarriageSrcs(urlDocs);
        setUploadFileNames(fnames);
    }

    return (<div>
        <Header screenName="결혼 경험" skipAvailable={ memberInfo.marriageStatusType !=0  ? true : false} nextFn={() =>{navigate("/academy");}} />
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">결혼 경험 여부</div>
            <div className={descriptionSmallText}>결혼 경험이 있는지 입력해 주세요</div>

            <div className="text-sm mt-40p">결혼 경험</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={marriageExperience == false ? smallBtnSelectedStyle : smallBtnNormalStyle} onClick={(ev) => setMarriageExperience(false)}><span className={marriageExperience == false ? "text-col_main_pink" : "text-col_button_gray_text"}>미혼</span></button>
                <button className={marriageExperience ? smallBtnSelectedStyle + " ml-10p" : smallBtnNormalStyle + " ml-10p"} onClick={(ev) => setMarriageExperience(true)}><span className={marriageExperience == true ? "text-col_main_pink" : "text-col_button_gray_text"}>재혼</span></button>
            </div>
            <div className="text-sm mt-40p">자녀 유무</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={haveChild == false ? smallBtnSelectedStyle : smallBtnNormalStyle} onClick={(ev) => setHaveChild(false)}><span className={haveChild == false ? "text-col_main_pink" : "text-col_button_gray_text"}>없음</span></button>
                <button className={haveChild ? smallBtnSelectedStyle + " ml-10p" : smallBtnNormalStyle + " ml-10p"} onClick={(ev) => setHaveChild(true)}><span className={haveChild == true ? "text-col_main_pink" : "text-col_button_gray_text"}>있음</span></button>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="flex flex-row justify-between mb-15p">
                    <div className="text-sm">증명서 첨부</div>
                    <div className="text-sm text-col_font_gray">인증해서 매칭 확률을 높혀 보세요</div>
                </div>
                <label htmlFor="file-input" className="w-334p">
                    <div className={btnProofDocUpload} >
                        <img src={fileUpload} alt="결혼 상태 증명 파일 업로드 버튼"/><span className=" text-white text-base ml-1.5">증명서 첨부</span>
                    </div>
                </label>
                <div className="text-sm text-col_font_gray mt-2 ml-2">미혼:혼인관계증명서, 무자녀 재혼:가족관계증명서</div>
                <a href="https://efamily.scourt.go.kr/index.jsp" target='_blank'><span className="text-sm text-col_font_gray ml-2 underline">증명서 발급: https://efamily.scourt.go.kr/index.jsp </span></a>
                <input type="file" id="file-input" className="" onChange={handleUploadDoc} multiple hidden />

                <div className={uploadfileNames.length > 0  ? "text-sm text-col_font_gray mt-6" : "hidden"}>* 첨부된 파일  ({uploadfileNames.length}개) </div>
                {
                    uploadfileNames.map((eachFileName:string)=>{
                        return <div className="text-sm text-col_font_gray">{eachFileName}</div>
                    })
                }
            </div>
            <div className="mb-20"></div>
            {/* <div className={marriageExperience != undefined  && haveChild != undefined  ? nextBtnStyle + " mt-20" : btnNomalStyle2 + " mt-20"} onClick={goToAcademy}><span className=" text-white text-base">다음으로</span></div> */}
            <NextBtn btnTitle={"다음으로"} ableCondtidion={marriageExperience != undefined  && haveChild != undefined  ? true : false} nextScreen="academy" dataHandlingFn={goToAcademy} />
        </div>
        <SimpleModal title="업로드 에러" message={modalMessage} btn1="확인" type="simple" closeFire={() => {setOpen(false)}} open={open} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default Marriage;