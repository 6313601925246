import React from 'react';
import logo from './logo.svg';
// import './App.css';
import { BrowserRouter, Route, Link, Routes } from "react-router-dom";
import Login from './screens/pages/login';
import NotFound from './screens/pages/notfound';
import RecommendList from './screens/pages/recommendList';
import InputFavoriteType from './screens/pages/favoriteType';
import PrivateRoute from './screens/context/privateRoute';
import TermsNConditions from './screens/pages/termsNConditions';
import SignUp from './screens/pages/signup';
import SelfAuth from './screens/pages/selfAuth';
import Address from './screens/pages/address';
import Religion from './screens/pages/apperance';
import Photo from './screens/pages/photo';
import Video from './screens/pages/video';
import Income from './screens/pages/income';
import Academy from './screens/pages/academy';
import FavoriteType from './screens/pages/favoriteType';
import FavoriteWeight from './screens/pages/favoriteWeight';
import Marriage from './screens/pages/marriage';
import Job from './screens/pages/job';
import Landing from './screens/pages/landing';
import Apperance from './screens/pages/apperance';
import Parent from './screens/pages/parent';
import Introduction from './screens/pages/introduction';
import MemberDetail from './screens/pages/memberDetail';
import MatchingList from './screens/pages/matchingList';
import MyPage from './screens/pages/myPage';


function App() {
  return (
    <div className='flex flex-col items-center'>
    <BrowserRouter>
      <Routes>
        {/* <Route element={<PrivateRoute authentication={true} />}> */}
          <Route path="/myPage" element={<MyPage />} />
          <Route path="/recommendList" element={<RecommendList />} />
          <Route path="/matchingList" element={<MatchingList />} />
          <Route path="/memberDetail" element={<MemberDetail />} />
          <Route path="/address" element={<Address />} />
          <Route path="/apperance" element={<Apperance />} />
          <Route path="/photo" element={<Photo />} />
          <Route path="/video" element={<Video />} />
          <Route path="/parent" element={<Parent />} />
          <Route path="/income" element={<Income />} />
          <Route path="/introduction" element={<Introduction />} />
          <Route path="/academy" element={<Academy />} />
          <Route path="/job" element={<Job />} />
          <Route path="/marriage" element={<Marriage/>} />
          <Route path="/favoriteType" element={<FavoriteType />} />
          <Route path="/favoriteWeight" element={<FavoriteWeight />} />
        {/* </Route> */}

        <Route path="/" element={<Landing />} />
        <Route path="/landing/:recId" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/selfAuth" element={<SelfAuth />} />
        <Route path="/termsnconditions" element={<TermsNConditions />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
    </div>
  );
}

export default App;
