import React, { useState, useContext, useEffect, useCallback } from "react";
import backArrow from '../../../img/back_arrow.png';
import MemberContext from "../../context/memberContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { HeaderProps } from "../../../common/dataType";

const Header = (props : HeaderProps) => {
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    const goBack = () => {
        navigate(-1);
    };

    const goNext = () => {
        if(props.skipAvailable == true && props.nextFn){
            props.nextFn();
        }
    };

    return (<>
        <div className="flex flex-row items-center justify-between h-55p w-334p mt-11p">
            <div className="flex flex-row items-center w-1/3" onClick={goBack}>
                <img src={backArrow} className="ml-2" alt="뒤로 가기" />
            </div>
            <div className="text-lg">{props.screenName}</div>
            {props.skipAvailable ? <div className="text-sm text-col_main_pink w-1/3 text-right mr-4" onClick={goNext}>건너뛰기</div> : <div className="w-1/3 "></div>}
            
        </div>
    </>)
}

export default Header;