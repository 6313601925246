import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Footer from "./common/footer";
import MemberContext from "../context/memberContext";
import common, { s3address } from "../../common/common";
import { Member, Preference } from "../../common/dataType";
import { getMarriageStatus, getPassowrdHiddenChars, getSiblingOrder } from "./common/commonMethod";


const MyPage = () => {
    const memberInfo = useContext(MemberContext);
    const [myInfo, setMyInfo] = useState<Member>();
    const [myPreference, setMyPreference] = useState<Preference>();
    let navigate = useNavigate();
    useEffect(() => {
        const getMemberInfo = async () => {
            let member = await axios.get(common.SERVER_URL + "/member/" + memberInfo.id);
            let preference = await axios.get(common.SERVER_URL + "/member/preference/" + memberInfo.id);
            console.log("preference > ", preference);
            setMyInfo(member.data.member);
            setMyPreference(preference.data.preference);
        }
        getMemberInfo();
    }, []);

    useEffect(() => {
        console.log(" myInfo >> ", myInfo);
    }, [myInfo])

    const goToModification = (destination: string) => {
        navigate("/" + destination);
    };

    const getReligionFathfullness = (faith: number | undefined) => {
        let faithStr = "";
        if (faith) {
            switch (faith) {
                case 1:
                    faithStr = "독실하지 않음";
                    break;
                case 2:
                    faithStr = "별로 독실하지 않음";
                    break;
                case 3:
                    faithStr = "보통";
                    break;
                case 4:
                    faithStr = "독실함";
                    break;
                case 5:
                    faithStr = "매우 독실함";
                    break;
            }
        }
        return faithStr;
    }

    const getReligion = (religion: string | undefined) => {
        let religionStr = "";
        if (religion) {
            switch (religion) {
                case "1":
                    religionStr = "개신교";
                    break;
                case "2":
                    religionStr = "천주교";
                    break;
                case "3":
                    religionStr = "불교";
                    break;
                case "4":
                    religionStr = "유대교";
                    break;
                case "5":
                    religionStr = "이슬람교";
                    break;
                case "6":
                    religionStr = "힌두교";
                    break;
                case "7":
                    religionStr = "기타종교";
                    break;
                case "10":
                    religionStr = "무교";
                    break;
            }
        }
        return religionStr;
    }

    const getFavorTypes = (type: string) => {
        let favorTypes = "";
        if (type == "bodyType") {
            myPreference?.bodyType.map((bodyType, index) => {
                if (index == 0) {
                    favorTypes = bodyType
                } else {
                    favorTypes = favorTypes + ", " + bodyType
                }
            });
        } else if (type == "religion") {
            myPreference?.religion.map((bodyType, index) => {
                if (index == 0) {
                    favorTypes = bodyType
                } else {
                    favorTypes = favorTypes + ", " + bodyType
                }
            });
        } else if (type == "marriageStatus") {
            myPreference?.marriageStatus.map((bodyType, index) => {
                if (index == 0) {
                    favorTypes = bodyType
                } else {
                    favorTypes = favorTypes + ", " + bodyType
                }
            });
        } else if (type == "accademy") {
            myPreference?.accademy.map((bodyType, index) => {
                if (index == 0) {
                    favorTypes = bodyType
                } else {
                    favorTypes = favorTypes + ", " + bodyType
                }
            });
        }
        return favorTypes;
    }

    return (
        <>
            <div className="flex flex-col ml-4 mb-20 w-334p">
                <div className="flex flex-row items-center justify-center h-55p w-full mt-11p mb-2">
                    <div className="text-lg font-bold">내 정보</div>
                </div>
                <div>해당 부분을 클릭하시면 수정화면으로 이동합니다</div>
                <div className="mt-5 mb-3">* 기본 정보</div>
                <div className="flex flex-row items-center">
                    <img className="w-24 h-24 rounded-full" src={s3address + myInfo?.profileImgUrls[0]} onClick={() => { goToModification("photo") }} alt="프로파일 이미지"/>
                    <div className="flex flex-col ml-8" onClick={() => { goToModification("selfAuth") }}>
                        <div>이름:{myInfo?.name} </div>
                        <div>생년월일: {myInfo?.birth} </div>
                        <div>핸드폰: {myInfo?.mobile}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>
                <div className="mt-3 mb-3">* 계정 정보</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("signup") }}>
                    <div className="flex flex-col">
                        <div>ID(메일):{myInfo?.email} </div>
                        <div>패스워드: {getPassowrdHiddenChars(memberInfo?.plainPass)} </div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 주소 / 종교</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("address") }}>
                    <div className="flex flex-col">
                        <div>주소: {myInfo?.address}</div>
                        <div>종교: {getReligion(myInfo?.religion) + " / " + getReligionFathfullness(myInfo?.faithfullness)}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 키 / 몸무게</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("apperance") }}>
                    <div className="flex flex-col">
                        <div>키: {myInfo?.height}cm</div>
                        <div>몸무게: {myInfo?.weight}kg</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 결혼</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("marriage") }}>
                    <div className="flex flex-col">
                        <div>결혼 및 자녀 여부: {myInfo?.marriageStatusType ? getMarriageStatus(myInfo?.marriageStatusType) : ""}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 학력</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("academy") }}>
                    <div className="flex flex-col">
                        <div>최종학력: {myInfo?.lastSchoolName + " " + myInfo?.lastEducation}</div>
                        <div>전공: {myInfo?.major}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 직업</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("job") }}>
                    <div className="flex flex-col">
                        <div>직장 분류: {myInfo?.workPlaceType}</div>
                        <div>직장명: {myInfo?.workPlaceName}</div>
                        <div>직위: {myInfo?.jobPosition}</div>
                        <div>직업명: {myInfo?.jobName}</div>
                        <div>직업 상세: {myInfo?.jobDescription}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 소득 / 자산</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("income") }}>
                    <div className="flex flex-col">
                        <div>연봉: {myInfo?.yearEarning}</div>
                        <div>현금성 자산: {myInfo?.cash}</div>
                        <div>부동산 자산: {myInfo?.realEstate}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 자기소개</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("introduction") }}>
                    <div className="flex flex-col">
                        <div>자기 소개</div>
                        <div>{myInfo?.introduction}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 부모님 / 가족관계</div>
                <div className="flex flex-col" onClick={() => { goToModification("parent") }}>
                    <div className="flex flex-row">
                        <div className="flex flex-col">
                            <div>[아버지]</div>
                            <div className="ml-2">
                                <div>최종학력:{myInfo?.father?.lastEducation} </div>
                                <div>직업: {myInfo?.father?.job}</div>
                                <div>직위: {myInfo?.father?.jobPosition}</div>
                                <div>은퇴 여부: {myInfo?.father?.isRetired ? "은퇴" : "현직"}</div>
                                <div>작고 여부: {myInfo?.father?.isPassedAway ? "작고" : "생존"}</div>
                            </div>

                        </div>
                        <div className="flex flex-col ml-12">
                            <div>[어머니]</div>
                            <div className="ml-2">
                                <div>최종학력:{myInfo?.mother?.lastEducation} </div>
                                <div>직업: {myInfo?.mother?.job}</div>
                                <div>직위: {myInfo?.mother?.jobPosition}</div>
                                <div>은퇴 여부: {myInfo?.mother?.isRetired ? "은퇴" : "현직"}</div>
                                <div>작고 여부: {myInfo?.mother?.isPassedAway ? "작고" : "생존"}</div>
                            </div>

                        </div>
                    </div>
                    <div className="mt-4">부모님 재산:{myInfo?.parentAsset}</div>
                    <div>형재 / 자매:{myInfo?.siblingOrder ? getSiblingOrder(myInfo?.siblingOrder) : ""}</div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 선호 타입</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("favoriteType") }}>
                    <div className="flex flex-col">
                        <div>나이: {myPreference?.ageMin ? myPreference?.ageMin : "" + " ~ " + myPreference?.ageMax ? myPreference?.ageMax : ""}</div>
                        <div>키: {myPreference?.heightMin ? myPreference?.heightMin : "" + " ~ " + myPreference?.heightMax ? myPreference?.heightMax : "" }</div>
                        <div>체형: {getFavorTypes("bodyType")}</div>
                        <div>종교: {getFavorTypes("religion")}</div>
                        <div>학력: {getFavorTypes("accademy")}</div>
                        <div>결혼여부: {getFavorTypes("marriageStatus")}</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>

                <div className="mt-3 mb-3">* 선호 비중</div>
                <div className="flex flex-row items-center" onClick={() => { goToModification("favoriteWeight") }}>
                    <div className="flex flex-col">
                        <div>외모/신체적 매력: {myPreference?.preferenceWeight[0]}%</div>
                        <div>사회/경제적 조건: {myPreference?.preferenceWeight[1]}%</div>
                        <div>성격: {myPreference?.preferenceWeight[2]}%</div>
                        <div>나이: {myPreference?.preferenceWeight[3]}%</div>
                        <div>가정환경: {myPreference?.preferenceWeight[4]}%</div>
                    </div>
                </div>
                <div className="bg-slate-200 w-80 h-1p mr-4 mt-3"></div>
            </div>
            <div className="fixed bottom-0 bg-white w-334p">
                <Footer current={3} />
            </div>
        </>
    );
}

export default MyPage;