import axios from "axios";
import common from "../../../common/common";
import { Member, Parent } from "../../../common/dataType";
import dayjs from "dayjs";
import mydearLogo from '../../../img/logo_mydear.png';

const now = dayjs();
export const getUserInfo = async (userId:number) : Promise<Member> => {
   let userInfo = await axios.get(common.SERVER_URL+"/member/"+userId); 
   return userInfo.data.member;  
}

export const showHideName = (fullName:string) =>{
   let arryFullName = fullName.split(''); 
   let changedName = arryFullName.map((each,index) =>{
       if(index == 0){
           return each;
       }else{
           return "*";
       }
   })
   return changedName.toString().replaceAll(",","");
}

export const getAge = (birthYear:number) => {
   console.log('now >> ', now);
   console.log('birthYear >> ', birthYear);
   let age : number = now.get("year") - birthYear + 1;
   return age; 
}

export const getMarriageStatus = (statusType:number) => {
   if(statusType == 1){
      return "미혼";
   }else if(statusType == 2){
      return "재혼(자녀없음)"
   }else if(statusType == 3){
      return "재혼(자녀있음)"
   }
}

export const getYearEarningWithFormat = (yearEarning:number) => {
   let strYearEarning = "";
   let share = yearEarning / 10000;
   let reminder = yearEarning % 10000;
   if(yearEarning >= 10000){
      strYearEarning = share+"억"
   }else{
      strYearEarning = reminder+"만원";
   }

   return strYearEarning;
}

export const getParentPassedAwayInfo = (father: Parent, mother: Parent) => {
   let parentPassedAway = "";

   if(!father || !mother){
      return parentPassedAway;
   }

   if(father.isPassedAway && mother.isPassedAway){
      parentPassedAway = "양친 작고";
   }else if(father.isPassedAway && !mother.isPassedAway){
      parentPassedAway = "부친 작고";
   }else if(!father.isPassedAway && mother.isPassedAway){
      parentPassedAway = "모친 작고";
   }else{
      parentPassedAway = "양친 생존";
   }

   return parentPassedAway;
}

export const getSiblingOrder = (sbilingOrder:string) => {
   
   let finalSiblingOrder = "";
   if(!sbilingOrder){
      return finalSiblingOrder;
   }
   let manSibling = sbilingOrder.substring(0,1) == "0" ? "무남" : sbilingOrder.substring(0,1)+"남";
   let womanSibling = sbilingOrder.substring(1,2) == "0" ? "무녀" : sbilingOrder.substring(1,2)+"녀";
   let order = sbilingOrder.substring(2,3)+"째";

   finalSiblingOrder = manSibling+" "+ womanSibling+ " "+order;
   return finalSiblingOrder;
}

export const getPassowrdHiddenChars = (pass:string | undefined) => {
   let returnChars = "";
   if(pass){
      for(let each of pass){
         console.log(" each > ", each);
         returnChars = returnChars + "*";
      }
   }
   return returnChars;
}

// I hope to share mydearLink(https://mydearyou.com) via KakaoTalk messanger app
export const shareViaKakao = (userId:string) => {
      const kakao = window.Kakao;
      const kakaoLink = "https://mydearyou.com/landing/"+userId;
      console.log("kakao api >>> ",  process.env.KAKAO_API_KEY);

      if (!kakao.isInitialized()) {
         //  kakao.init(process.env.KAKAO_API_KEY);
          kakao.init("fc22a37a3b706fcfeff2599cedc8b836");
      }

      kakao.Link.sendDefault({
          objectType: 'feed',
          content: {
              title: 'MyDear에서 연인을 찾아보세요',
              description: '합리적인 사람들의 진실한 만남',
              imageUrl: mydearLogo,
              link: {
                  mobileWebUrl: kakaoLink,
                  webUrl: kakaoLink
              }
          },
          // buttons: [
          //     {
          //         title: '웹으로 보기',
          //         link: {
          //             mobileWebUrl: kakaoLink,
          //             webUrl: kakaoLink
          //         }
          //     }
          // ]
      });
  }