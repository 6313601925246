import { Dialog } from "@mui/material"
import { useEffect, useState } from "react";
import { modalData, modalDataEx } from "../../../common/dataType";
import close_x from '../../../img/close_x_gray.png';

export const SimpleModal = (props:modalData) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        props.closeFire();
    };
    useEffect(() => {
        setOpen(props.open);
    },[])

    return (
        <Dialog open={props.open} onClose={handleClose} className="border rounded-xl">
            <div className="flex flex-col rounded-xl items-center justify-center w-72 h-70">
                <div className="flex flex-row items-center justify-center h-14">
                    <div className="w-24"></div>
                    <div className="text">{props.title}</div>
                    <div className="flex flex-row justify-end w-24">
                        <img src={close_x} onClick={handleClose} alt="닫기 버튼" />
                    </div>
                </div>
                <div className="h-1/4 w-full bg-slate-200"></div>
                <div className="flex flex-col items-end justify-center h-40 p-3 ">
                    <span>{props.message}</span>
                </div>
                {
                 props.type=="simple" ? <div className="h-16 w-full text-center flex flex-col justify-center bg-col_main_pink text-white" onClick={handleClose}>{props.btn1}</div> 
                 : <div className="flex flex-row items-center"><div className="h-16 w-167p text-center flex flex-col justify-center " onClick={handleClose}>확인</div><div className="h-16 w-167p text-center flex flex-col justify-center bg-col_main_pink text-white " onClick={props.btn2fn}>{props.btn2}</div></div> 
                }
            </div>
        </Dialog>
    )
}

export const ExtendsModal = (props:modalDataEx) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        props.closeFire();
    };
    useEffect(() => {
        setOpen(props.open);
    },[])

    return (
        <Dialog open={props.open} onClose={handleClose} className="border rounded-xl">
            <div className="flex flex-col rounded-xl items-center justify-center w-72 h-70">
                <div className="flex flex-row items-center justify-center h-14">
                    <div className="w-20"></div>
                    <div className="font-bold">{props.title}</div>
                    <div className="flex flex-row justify-end w-20 pr-2">
                        <img src={close_x} onClick={handleClose} alt="닫기 버튼" />
                    </div>
                </div>
                <div className="h-1/4 w-full bg-slate-200"></div>
                <div className="flex flex-col justify-center h-40 p-3 ">
                    <div>{props.message1}</div>
                    <div>{props.message2}</div>
                </div>
                {
                 props.type=="simple" ? <div className="h-16 w-full text-center flex flex-col justify-center bg-col_main_pink text-white" onClick={handleClose}>{props.btn1}</div> 
                 : <div className="flex flex-row w-">
                     <div className="h-16 w-36 text-center flex flex-col justify-center border" onClick={handleClose}>{props.btn1}</div>
                     <div className="h-16 w-36 flex flex-col justify-center items-center border bg-col_main_pink text-white " onClick={props.btn2fn}>{props.btn2}</div>
                </div> 
                }
            </div>
        </Dialog>
    )
}