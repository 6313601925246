import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { LoginInfo, religions, selectData } from "../../common/dataType";
import Header from "./common/header";
import { btnContinueStyle2, btnNomalStyle2, btnNomalStyle3, inputBoxNormalStyle, nextBtnStyle } from "../../common/commonStyle";
import searchImg from "../../img/search_glass.png";
import closeX from '../../img/close_x_gray.png';
import circleCheck from '../../img/circle_check_pink.png';
import circleNone from '../../img/circle_check_none.png';

import DaumPostcode from "react-daum-postcode";
import { Dialog } from "@mui/material";
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";
import { getUserInfo } from "./common/commonMethod";
import Loading from "./common/loading";

const Address = () => {
    const [address, setAddress] = useState("");
    const [bcode, setBcode] = useState("");
    const [religion, setReligion] = useState("1");
    const [faith, setFaith] = useState(0);
    const [open, setOpen] = useState(false);
    const memberInfo = useContext(MemberContext);
    const [loadingOpen, setLoadingOpen] = useState(false);
    console.log("memberInfo >> ", memberInfo);
    
    let navigate = useNavigate();

    useEffect(()=>{
        setAddress(memberInfo.address);
        setReligion(memberInfo.religion);
        setFaith(memberInfo.faithfullness);
    },[]);

    useEffect(()=>{
        console.log("faith >> ", faith);
    },[faith])

    const handleAddress = async () => {
        setLoadingOpen(true);
        let serverUploadResult = {ok:false, type:"none"};
        let result = await axios.post(common.SERVER_URL+"/member/updateMember", 
        {id:memberInfo.id , address:address, bcode:bcode, religion:religion, faithfullness:faith});
        console.log(" address result >> ", result.data);    
        setLoadingOpen(false);
        if(result.data.ok){
            serverUploadResult.ok = true;
            serverUploadResult.type = "db";
            memberInfo.address = result.data.member.address;
            memberInfo.religion = result.data.member.religion;
            memberInfo.faithfullness = result.data.member.faithfullness;
        }
        return serverUploadResult;
    }

    const handleSearch = (data:any) => {
        console.log(" handleSearch >> ", data);
    }

    const hanldeComplete = (data:any) => {
        setOpen(false);
        console.log(" hanldeComplete >> ", data);
        setAddress(data.address);
        setBcode(data.bcode);
    }

    const popupAddressInput = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const clickFaith = (faithDegree:number) => {
        setFaith(faithDegree);
    }

    const AddressModal = () => {
        return (
            <Dialog open={open} onClose={handleClose} className="border rounded-xl">
                <div className="flex flex-col rounded-xl items-center justify-center w-80 h-70">
                    <div className="flex flex-row items-center justify-between h-14">
                        <div>주소를 입력해 주세요</div>
                        <img src={closeX} onClick={handleClose} className="ml-28" />
                    </div>
                    <div className="h-1/4 w-full bg-slate-200"></div>
                    <div className="flex flex-col items-end justify-center h-76 ">
                        <DaumPostcode onComplete={hanldeComplete} onSearch={handleSearch} className="w-32"/>
                    </div>

                </div>
            </Dialog>
        ) 
    }

    return(<div>
        <Header screenName="주소, 종교" skipAvailable={memberInfo.address && memberInfo.religion ? true : false} nextFn={() =>{navigate("/apperance");}} />
        <div className="ml-5 mr-5 mt-30p">
            <div className="text-xl">가입을 환영합니다!</div>
            <div className="text-xl mt-10">현재 거주지</div>
            <div className="text-sm text-col_font_gray mt-10p">최적의 미팅 장소 추천에 사용됩니다.</div>

            <div className={open ? "hidden" : btnNomalStyle3+" mt-8"} onClick={popupAddressInput}>
                <div className={address ? "text-sm ml-4" : "text-sm text-col_font_gray ml-4" }>{address ? address : "현재 거주지를 입력해 주세요"}</div><img src={searchImg} className="ml-24" alt="거주지 찾기 입력 버튼"/>
            </div>
            <div className="text-xl mt-12">종교</div>
            <div className="text-sm text-col_font_gray mt-10p">본인의 종교를 선택해 주세요.</div>
            <select onChange={(ev) => { setReligion(ev.target.value) }} className={inputBoxNormalStyle +" w-334p mt-"} value={religion}>
                {
                    religions.map((eachReligion: selectData) => {
                        return <option value={eachReligion.value}>{eachReligion.label}</option>
                    })
                }
            </select>
            <div className={religion == "10" ? "hidden" : ""}>
                <div className="mt-30p text-sm text-col_font_gray">신앙심을 선택해 주세요.</div>
                <div className="relative mt-9">
                    <div className="flex flex-row items-center ml-2 ">
                        <img src={faith == 1 ? circleCheck : circleNone} alt="신앙심 지수 1" className="w-18p h-18p z-10" onClick={(ev) => clickFaith(1)}/>
                        <img src={faith == 2 ? circleCheck : circleNone} alt="신앙심 지수 2" className="w-18p h-18p z-10 ml-61p" onClick={(ev) => clickFaith(2)}/>
                        <img src={faith == 3 ? circleCheck : circleNone} alt="신앙심 지수 3" className="w-18p h-18p z-10 ml-61p" onClick={(ev) => clickFaith(3)}/>
                        <img src={faith == 4 ? circleCheck : circleNone} alt="신앙심 지수 4" className="w-18p h-18p z-10 ml-61p" onClick={(ev) => clickFaith(4)}/>
                        <img src={faith == 5 ? circleCheck : circleNone} alt="신앙심 지수 5" className="w-18p h-18p z-10 ml-61p" onClick={(ev) => clickFaith(5)}/>
                        <div className="absolute top-2 left h-2p w-334p bg-col_button_gray z-0"></div>
                    </div>
                    <div className="flex flex-row items-between mt-2 ml-2">
                        <div className="text-xs  text-gray-500">독실하지 않음</div>
                        <div className="w-60"></div>
                        <div className="text-xs text-gray-500">독실함</div>
                    </div>
                </div>
            </div>
            <div className="h-20"></div>
            <NextBtn btnTitle="다음으로" ableCondtidion={address && religion ? true : false} nextScreen="apperance" dataHandlingFn={handleAddress} />
            {AddressModal()}
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>

    </div>);
}

export default Address;