import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common, { s3address } from "../../common/common";
import { LoginInfo } from "../../common/dataType";
import Header from "./common/header";
import MemberContext from "../context/memberContext";
import { btnContinueStyle2, btnNomalStyle2, nextBtnStyle } from "../../common/commonStyle";
import profileBackLarge from '../../img/profile_img_large.png';
import profileBackSmall from '../../img/profile_img_small.png';
import { SimpleModal } from "./common/modal";
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Video = () => {
    const [profileVideoFiles, setProfileVideoFiles] = useState<Array<any>>([]);
    const [profileVideoSrc, setProfileVideoSrc] = useState<Array<string>>([]);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const videoRef = useRef<HTMLInputElement>(null);
    const [source, setSource] = useState<string>("");
    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    
    useEffect(() => {
        const getImgURLs = async () => {
            let result = await axios.get(common.SERVER_URL + "/member/"+memberInfo.id);
            console.log(" result member >> ", result.data);
            
            console.log(" memberInfo.motionPicUrls >> ", memberInfo.motionPicUrls);

            if(result.data.member){
                let urls = result.data.member.motionPicUrls;
                console.log(" urls >> ", urls);
                let finalUrls = urls.map((eachUrl:string)=>{
                    return s3address+eachUrl;
                });
                console.log(" url 222 >>> ", finalUrls[0]);
                setSource(finalUrls[0]);
            }else{

            }
        }; 
        getImgURLs();
    },[]);

    const handleVideo = async () => {
        let serverResult = {ok:false, type:'none'};
        setLoadingOpen(true);
        let formData = new FormData();
        let fileNames = [];
        let lastModifieds = "";
        for (let i = 0; i < profileVideoFiles.length; i++) {
            formData.append("files", profileVideoFiles[i]);
            let fileName = "motionPic/" + profileVideoFiles[i].lastModified +"_"+memberInfo.id;  // DB에 들어가는 FileName
            let trimedFileName = fileName.replace(/ /g, "");
            fileNames.push(trimedFileName);
            lastModifieds = lastModifieds + profileVideoFiles[i].lastModified + "_" + memberInfo.id+"#";
        }

        formData.append("filePath", "motionPic/");
        formData.append("lastModifieds", lastModifieds);

        console.log(" fileNames >> ", fileNames);
        if(memberInfo.id != 0){
            let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", {id: memberInfo.id ,motionPicUrls:fileNames});
            let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
            setLoadingOpen(false);
            if (s3Result.status == 201) {
                if(memberInfo.motionPicUrls){
                    // 기존 동영상이 있으면 지워야 한다.
                    if(fileNames[0] != memberInfo.motionPicUrls[0]){  // 기존 동영상과 새로 업데이트하는 영상이 같은 경우(즉 같은 파일을 올리는 경우)는 실행 시키면 안된다. 
                        let s3DeleteResult = await axios.post(common.SERVER_URL + "/common/s3ImgFiles", memberInfo.motionPicUrls);
                        // 지우고 memberInfo 업데이트
                        memberInfo.motionPicUrls = dbUpdate.data.member.motionPicUrls[0];
                    }

                }
                serverResult.ok = true;
                serverResult.type = 'file';
            } else {
                console.log(" error >> ", s3Result.statusText);
            }
        }else{
            setLoadingOpen(false);
            setOpen(true);
            setModalMessage("업로드 실패!");
        }
        return serverResult;
    }

    const handleVideoUpload = async (event: any) => {
        let fileSize = event.target.files[0].size;
        console.log(" fileSize >> ", fileSize);
        let imgs: Array<any> = event.target.files;
        console.log("imgs >> ", imgs);
        setProfileVideoFiles(imgs);
        let urlImgs: Array<string> = [];
        for (let i = 0; i < imgs.length; i++) {
            urlImgs.push(URL.createObjectURL(imgs[i]));
        }
        console.log(" urlImgs >>> ", urlImgs);
        setProfileVideoSrc(urlImgs);
    };

    const handleFileChange = (event : any) => {
        const file = event.target.files[0];
        const url = URL.createObjectURL(file);
        setProfileVideoFiles(event.target.files);
        console.log(" url 111 >>> ", url);
        setSource(url);
      };


    const goNext = () => {
        navigate('/marriage')
    }

    return (<div>
        <Header screenName="프로필 영상" skipAvailable={true} nextFn={goNext} />

        <div className="ml-5 mr-5 mt-30p" >
            <label htmlFor="file-input" className="w-334p">
                <video className="w-334p h-334p" poster={profileBackLarge} src={source} autoPlay loop />
                <div className="text-xs text-col_font_gray mt-15p ml-10">"안녕하세요. OOO 입니다."만 올려주셔도 충분합니다.</div>
            </label>
            <input type="file" id="file-input" className="" onChange={handleFileChange} accept="video/*" hidden ref={videoRef} />
            <div className="h-20"></div>
        <NextBtn btnTitle="다음으로" ableCondtidion={source && profileVideoFiles.length > 0 ? true : false} nextScreen="marriage" dataHandlingFn={handleVideo} />
        </div>
        <SimpleModal title="업로드 에러" message={modalMessage} btn1="확인" type="simple" closeFire={() => {setOpen(false)}} open={open} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default Video;