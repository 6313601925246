import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { LoginInfo } from "../../common/dataType";
import bigLogo from '../../img/loading_color_small.jpeg';
import close_x from '../../img/close_x_gray.png';
import { Dialog } from "@mui/material";
import { SimpleModal } from "./common/modal";
import MemberContext from "../context/memberContext";
import Loading from "./common/loading";


const Login = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const memberInfo = useContext(MemberContext);
    const [loadingOpen, setLoadingOpen] = useState(false);

    const getUploadedFileName = (totalFileName:string) => {
        let trimedFile1 = totalFileName.split("_")[0]+"_"+totalFileName.split("_")[1]+"_";
        let returnedFileName = totalFileName.replace(trimedFile1,"");
        console.log("returnedFileName >> ", returnedFileName);
        return returnedFileName;
    }
    const handleLogin = async () => {
        let loginInfo = {
            email: email,
            pass: pass
        }
        
        if(!email || !pass){
            setOpen(true);
            setModalMessage("메일 주소와 패스워드를 입력하세요");
            return;
        }
        
        setLoadingOpen(true);
        let loginResult = await axios.post(common.SERVER_URL + "/member/login", loginInfo);
        setLoadingOpen(false);
        console.log(" loginResult >> ", loginResult.data);
        if (!loginResult.data.ok) {
            setOpen(true);
            setModalMessage(loginResult.data.message);
        }else{
            memberInfo.id = loginResult.data.member.id;
            memberInfo.name = loginResult.data.member.name;
            memberInfo.email = loginResult.data.member.email;  
            memberInfo.sex = loginResult.data.member.sex;
            memberInfo.mobile = loginResult.data.member.mobile;
            memberInfo.marketingAgree = loginResult.data.member.maketingAgree;
            memberInfo.birth = loginResult.data.member.birth;
            
            memberInfo.height = loginResult.data.member.height;
            memberInfo.weight = loginResult.data.member.weight;
            
            memberInfo.address = loginResult.data.member.address;
            memberInfo.religion = loginResult.data.member.religion;
            memberInfo.faithfullness = loginResult.data.member.faithfullness;
            
            memberInfo.profileImgUrls = loginResult.data.member.profileImgUrls;
            memberInfo.motionPicUrls = loginResult.data.member.motionPicUrls[0];
            
            memberInfo.marriageStatusType = loginResult.data.member.marriageStatusType;
            
            memberInfo.lastEducation = loginResult.data.member.lastEducation;
            memberInfo.lastSchoolName = loginResult.data.member.lastSchoolName;
            memberInfo.major = loginResult.data.member.major;

            memberInfo.workPlaceName = loginResult.data.member.workPlaceName;
            memberInfo.workPlaceType = loginResult.data.member.workPlaceType;
            memberInfo.jobPosition = loginResult.data.member.jobPosition;
            memberInfo.jobName = loginResult.data.member.jobName;
            memberInfo.jobDescription = loginResult.data.member.jobDescription;

            memberInfo.yearEarning = loginResult.data.member.yearEarning;
            memberInfo.cash = loginResult.data.member.cash;
            memberInfo.realEstate = loginResult.data.member.realEstate;
            memberInfo.realEstateDes = loginResult.data.member.realEstateDes;

            memberInfo.parentAsset = loginResult.data.member.parentAsset;
            memberInfo.father = loginResult.data.member.father;
            memberInfo.mother = loginResult.data.member.mother;

            memberInfo.sibling = loginResult.data.member.siblingOrder;

            memberInfo.introduction = loginResult.data.member.introduction;
            memberInfo.plainPass = pass;
            

            let preferenceResult = await axios.get(common.SERVER_URL + "/member/preference/"+memberInfo.id);
            console.log(" preference Result >> ", preferenceResult);
            if(preferenceResult.data.preference){
                memberInfo.ageMax = preferenceResult.data.preference.ageMax;
                memberInfo.ageMin = preferenceResult.data.preference.ageMin;
                memberInfo.heightMax = preferenceResult.data.preference.heightMax;
                memberInfo.heightMin = preferenceResult.data.preference.heightMin;
                memberInfo.favAccademy = preferenceResult.data.preference.accademy;
                memberInfo.favBodyType = preferenceResult.data.preference.bodyType;
                memberInfo.favMarrigeStatus = preferenceResult.data.preference.marriageStatus;
                memberInfo.favReligion = preferenceResult.data.preference.religion;
                memberInfo.preferenceWeight = preferenceResult.data.preference.preferenceWeight;
            }

            // 증명성 // ==================
            memberInfo.marriageProofDocsName = loginResult.data.member.marriageProofUrl.map((each:string) => { return getUploadedFileName(each)});
            memberInfo.schoolProofDocsName = loginResult.data.member.academyProofUrl.map((each:string) => { return getUploadedFileName(each)});
            memberInfo.jobProofDocsName = loginResult.data.member.jobProofUrl.map((each:string) => { return getUploadedFileName(each)});
            memberInfo.incomeProofDocsName = loginResult.data.member.incomeProofUrl.map((each:string) => { return getUploadedFileName(each)});
            memberInfo.assetProofDocsName = loginResult.data.member.assetProofUrl.map((each:string) => { return getUploadedFileName(each)});
            
            // address -> appreance -> photo -> video -> marriage -> academy -> job -> income   
            // if(!result.data.member.address || !result.data.member.religion){
            //     navigate('/address');
            // }else if(!result.data.member.height){
            //     navigate('/apperance');
            // }else if(result.data.member.profileImgUrls.length == 0){
            //     navigate('/photo');
            // }else if(result.data.member.motionPicUrls.length == 0){
            //     navigate('/video');
            // }else if(result.data.member.marriageStatusType == 0){
            //     navigate('/marriage');
            // }else if(!result.data.member.lastEducation || !result.data.member.lastSchoolName){
            //     navigate('/acamedy');
            // }else if(!result.data.member.jobName){
            //     navigate('/job');
            // }else{
            //     navigate('/parent')
            // }
            navigate('/recommendList');
        }
    }

    const handleSignup = () => {
        navigate("/termsnconditions");
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (<div className="ml-7 mr-7">
        <div className="text-2xl mt-8">마이디어(MyDear)</div>
        <div className="text-base mt-7">합리적인 사람들의 내사람 찾기</div>
        <div className="mt-10 ml-5"><img src={bigLogo} className="w-60 h-56" alt="마이디어 큰 로고"/></div>
        <div className="flex flex-col mt-10 ">
            <span className="text-sm">이메일</span>
            <input type="text" className="pl-3 border h-10 w-334p border-t-0 border-l-0 border-r-0 border-b-col_font_gray_light" onChange={(ev) => { setEmail(ev.target.value) }}></input>

            <span className="text-sm mt-7">비밀번호</span>
            <input type="password" className="pl-3 border h-10 w-334p border-t-0 border-l-0 border-r-0 border-b-col_font_gray_light" onChange={(ev) => { setPass(ev.target.value) }}></input>

            <button onClick={handleLogin} className="border rounded-full w-334p h-14 mt-5
             bg-col_main_pink 
             flex flex-col items-center justify-center
             hover:bg-pink-600 active:bg-pink-700">
                <span className=" text-white text-base">로그인</span>
            </button>
            <div onClick={handleSignup} className="border rounded-full w-334p h-14 mt-2.5
             border-col_main_pink flex flex-col items-center justify-center hover:bg-pink-300">
                 <span className=" text-col_main_pink text-base">회원가입</span></div>
        </div>
        <SimpleModal title="로그인" message={modalMessage} btn1="확인" type="simple" closeFire={handleClose} open={open} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default Login;