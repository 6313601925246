import React, { useState, useContext, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Footer from "./common/footer";
import Header from "./common/header";
import { Matching, Member, MemberDetailProps } from "../../common/dataType";
import common from "../../common/common";
import { s3address } from "../../common/common"
import MemberContext from "../context/memberContext";
import backArrow from '../../img/back_arrow_white.png';
import hambuger from '../../img/hambuger_horizontal.png';
import largeXCancel from '../../img/largeXwithCircle.png';
import medal_off from '../../img/medal_off.png';
import medal_on from '../../img/medal_on.png';
import { useSwipeable } from "react-swipeable";
import { getAge, getMarriageStatus, getParentPassedAwayInfo, getSiblingOrder, getYearEarningWithFormat, showHideName } from "./common/commonMethod";
import dayjs from "dayjs";
import { ExtendsModal, SimpleModal } from "./common/modal";

const MemberDetail = () => {
    const [profileImg, setProfileImg] = useState("");
    const [profileImgs, setProfileImgs] = useState<Array<string>>([""]);
    const [open, setOpen] = useState(false);
    const [extendsOpen, setExtendsOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessage1, setModalMessage1] = useState("");
    const memberInfo = useContext(MemberContext);
    const navigate = useNavigate();
    const location = useLocation();
    let count = 0;
    let imgs = [];
    const member: Member = location.state.member;
    const matching: Matching = location.state.matching;
    const proofedCss = "flex items-center justify-center rounded-2xl w-24 h-6 bg-green-600 text-white text-sm";
    const notProofedCss = "flex items-center justify-center rounded-2xl w-24 h-6 border-red-500 text-red-500 text-sm border";

    useEffect(() => {
        console.log("location.state.member >>> ", location.state.member);
        imgs = location.state.member.profileImgUrls.map((each: string) => {
            return s3address + each;
        })
        setProfileImgs(imgs)
        setProfileImg(profileImgs[0]);
       
    }, []);

    useEffect(() => {
        console.log("profileImg 222 >> ", profileImg);
    }, [profileImg])

    const goBack = () => {
        navigate(-1);
    };

    const GetImgUX = () => {

        const handlers = useSwipeable({
            onSwipedLeft: (ev) => {
                count++;
                console.log(" count >>> ", count);
                setProfileImg(profileImgs[count]);
            },
            onSwipedRight: (ev) => {
                count--;
                console.log(" count >>> ", count);
                setProfileImg(profileImgs[count]);
            },
            onSwipeStart: (ev: any) => { console.log("start >> ", count) },
            onSwiped: (ev) => { console.log("ddddddddd") },
        });

        // setup ref for your usage
        const myRef = useRef();

        const refPassthrough = (el: any) => {
            // call useSwipeable ref prop with el
            handlers.ref(el);

            // set myRef el so you can access it yourself
            myRef.current = el;
        }

        return <div className="relative w-334p h-375p" {...handlers} ref={refPassthrough}>
            <img src={s3address + location.state.member.profileImgUrls[count]} className="w-full h-full" />
            <div className="absolute top-10 left-0 right-0 h-50 flex flex-row items-center justify-between ">
                <img src={backArrow} className="ml-2" onClick={goBack} alt="뒤로가기 버튼" />
                <img src={hambuger} className="mr-4" alt="햄버거 버튼"/>
            </div>
        </div>
    };

    const cancelMeeting = () => {

    };

    const confirmMeeting = () => {
        setExtendsOpen(true);
    };

    const acceptMatching = async () => {
        let acceptedMatching = {};
        const now = dayjs();
        setExtendsOpen(false);
        if(memberInfo.sex == "m"){
            acceptedMatching = {id: matching.id, manId:memberInfo.id, manAcceptedTime: now};
        }else{
            acceptedMatching = {id: matching.id, womanId:memberInfo.id, womanAcceptedTime: now};
        }

        let acceptResult = await axios.post(common.SERVER_URL+"/matching/acceptMatching",acceptedMatching);
        if(acceptResult.data.ok){
            setOpen(true);
        }
    }

    const GetMemberInfoUx = () => {

        return (<div className="w-334p h-457p p-5">
            <div className="flex flex-row items-center">
                <div className="flex flex-col">
                    <div className="text-lg font-bold">{showHideName(member.name)}</div>
                    <div className="flex flex-row mt-1.5 text-col_font_999999">
                        <div className="w-64">{member.birth.substring(0, 4) + "(" + getAge(parseInt(member.birth.substring(0, 4))) + "세)" +" / " +member.height+"cm"}</div>
                    </div>
                    <div className="flex flex-row mt-4 items-center justify-center">
                        <div className="w-64  text-col_font_999999" >
                        {getMarriageStatus(member.marriageStatusType)}
                        </div>
                        <div className={member.isMarriageProofed ? proofedCss : notProofedCss}>{member.isMarriageProofed ? getMarriageStatus(member.marriageStatusType) + " 인증" : getMarriageStatus(member.marriageStatusType) + " 미인증"}</div>
                    </div>
                    <div className="flex flex-row mt-4 items-center justify-center">
                        <div className="flex flex-col">
                            <div className="w-64 text-col_font_999999">{member.jobName}</div>
                            <div className="w-64 text-col_font_999999">{member.jobDescription}</div>
                            <div className="w-64 text-col_font_999999">{member.workPlaceType + " / " + member.jobPosition}</div>
                        </div>


                        <div className={member.isJobProofed ? proofedCss : notProofedCss}>{member.isJobProofed ? "직업 인증" : "직업 미인증"}</div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="w-64 text-col_font_999999">
                            <span>{member.lastEducation}</span>
                            <span>{" / " + member.lastSchoolName}</span>
                        </div>
                        <div className={member.isAcademyProofed ? proofedCss : notProofedCss}>{member.isAcademyProofed ? "학력 인증" : "학력 미인증"}</div>
                    </div>
                    <div className="flex flex-row mt-4">
                        <div className="w-64 text-col_font_999999">연소득: {getYearEarningWithFormat(member.yearEarning)}</div>
                        <div className={member.isIncomeProofed ? proofedCss : notProofedCss}>{member.isIncomeProofed ? "소득 인증" : "소득 미인증"}</div>
                    </div>
                    <div className="flex flex-row mt-4 items-center ">
                        <div className="w-64 text-col_font_999999">
                            <div className="">현금자산: {getYearEarningWithFormat(member.cash)}</div>
                            <div>부동산자산: {getYearEarningWithFormat(member.realEstate)}</div>
                        </div>
                        <div className={member.isAssetProofed ? proofedCss : notProofedCss}>{member.isAssetProofed ? "자산 인증" : "자산 미인증"}</div>
                    </div>
                    <div className="flex flex-row mt-4 items-center ">
                        <div className="w-full text-col_font_999999">
                            <div>
                                <span>부모님: {getParentPassedAwayInfo(member.father, member.mother)}</span>
                                <span className={member.parentAsset == 0 ? "hidden" : "ml-1"}> / 부모님 재산: {getYearEarningWithFormat(member.parentAsset)}</span>
                            </div>
                            <div className={member.siblingOrder == "" ? "hidden" : ""}>가족관계: {getSiblingOrder(member.siblingOrder)}</div>
                        </div>
                    </div>
                    <div className="flex flex-col mt-7 ">
                        <div className="font-bold">자기 소개</div>
                        <textarea className="w-334p h-44 mt-1">
                            {member.introduction}
                        </textarea>
                    </div>
                    <div className="flex flex-row mt-3 items-center mb-10 ">
                        <img src={largeXCancel} className="ml-3 mr-4 " onClick={cancelMeeting} alt="만남 취소 버튼"/>
                        <button className="rounded-full h-55p w-64 bg-col_main_pink hover:bg-col_main_pink2 focus:ring focus:ring-col_main_pink text-white font-bold flex items-center justify-center" onClick={confirmMeeting}>
                            만남 수락
                        </button>
                    </div>
                </div>
            </div>
        </div>)
    }

    return (<div>
        {
            GetImgUX()
        }
        {
            GetMemberInfoUx()
        }
        <SimpleModal title="만남 수락" message={showHideName(member.name)+"님 과의 만남이 수락되었습니다."} btn1="확인" type="simple" closeFire={() => { setOpen(false) }} open={open} />
        <ExtendsModal title="만남 수락 확인" message1={showHideName(member.name)+"님 과의 만남을 수락하시겠습니까?"} message2="상호 만남 수락 확정시 취소는 불가능 합니다." btn1="취소" btn2="수락" type="double" closeFire={() => { setExtendsOpen(false) }} open={extendsOpen} btn2fn={acceptMatching} />
    </div>);
}



export default MemberDetail;