import React, { useState, useContext, useEffect, useCallback } from "react";
import matchingOffImg from '../../../img/matching_off.png';
import matchingOnImg from '../../../img/matching_on.png';
import meetingOffImg from '../../../img/meeting_off.png';
import meetingOnImg from '../../../img/meeting_on.png';
import myOffImg from '../../../img/my_off.png';
import myOnImg from '../../../img/my_on.png';
import MemberContext from "../../context/memberContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { FooterProps, HeaderProps } from "../../../common/dataType";

const Footer = (props : FooterProps) => {
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    const goRecommendList = () => {
        navigate('/recommendList');
    };

    const goMeetingList = () => {
        navigate('/matchingList');
    };

    const goMyPage = () => {
        navigate('/myPage');
    };

    const getFooterStyle = (onOff:String) => {
        return onOff == "on" ? "text-sm text-col_main_pink" : "text-sm text-col_font_gray"; 
    }

    return (<>
        <div className="flex flex-row items-center justify-between h-55p w-334p mt-11p">
            <div className="flex flex-col items-center justify-center w-1/3" onClick={goRecommendList}>
                <img src={ props.current == 1 ? matchingOnImg : matchingOffImg} alt="추천 리스트"/>
                <div className={props.current == 1? getFooterStyle("on") : getFooterStyle("off")}>추천</div>
            </div>
            <div className="flex flex-col items-center justify-center w-1/3" onClick={goMeetingList}>
                <img src={ props.current == 2 ? meetingOnImg : meetingOffImg} alt="매칭(만남) 리스트" />
                <div className={props.current == 2 ? getFooterStyle("on") : getFooterStyle("off")}>매칭</div>
            </div>
            <div className="flex flex-col items-center justify-center w-1/3" onClick={goMyPage}>
                <img src={props.current == 3 ? myOnImg : myOffImg} alt="나의 페이지"/>
                <div className={props.current == 3 ? getFooterStyle("on") : getFooterStyle("off")}>내 프로필</div>
            </div>
            
        </div>
    </>)
}

export default Footer;