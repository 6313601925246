import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Footer from "./common/footer";
import Header from "./common/header";
import { Matching, Member } from "../../common/dataType";
import common from "../../common/common";
import { s3address } from "../../common/common"
import MemberContext from "../context/memberContext";
import { shareViaKakao, showHideName } from "./common/commonMethod";

const MatchingList = () => {
    const [recommList, setRecommList] = useState<Array<Matching>>([]);
    const [mateList, setMateList] = useState<Array<Member>>([]);
    const memberInfo = useContext(MemberContext);
    let navigate = useNavigate();
    useEffect(() => {
        const getUserMathcing = async () => {
            let matchingResult = await axios.get(common.SERVER_URL + "/matching/" + memberInfo.id);
            if (matchingResult.data.ok) {
                setRecommList(matchingResult.data.matchingList);
                setMateList(matchingResult.data.matesList);
            }
        }
        getUserMathcing();

    }, []);

    const getProofStyle = (proofType: string) => {
        let styleToReturn = "flex flex-col justify-center items-center w-12 h-5 rounded-2xl bg-col_main_pink text-white font-bold text-xs";
        if (proofType == "part") {
            styleToReturn = "flex flex-col justify-center items-center w-14 h-5 rounded-2xl bg-slate-600 text-white font-semibold text-xs"
        } else if (proofType == "no") {
            styleToReturn = "flex flex-col justify-center items-center w-12 h-5 rounded-2xl border border-red-400 text-red-400 font-semibold text-xs"
        }
        return styleToReturn;
    };

    const moveToMemberDetail = (member: Member, matching: Matching) => {
        navigate("/memberDetail", { state: { member: member, matching: matching } })
    };

    const getMatchingList = (mateArr: Array<Member>, matchingArr: Array<Matching>) => {
        console.log(" mateArr >> ", mateArr);
        console.log(" matchingArr >> ", matchingArr);
        if (mateArr.length == 0) {
            return (
                <div className="flex flex-col w-334p h-40 items-center justify-center">
                    <div className="text-lg font-light">아직 매칭된 회원이 없습니다</div>
                </div>
            )
        } else {
            return (
                mateArr.map((mate, index) => {
                    let leftDays = 5;
                    let fullProofed = mate.isAcademyProofed && mate.isMarriageProofed && mate.isJobProofed && mate.isIncomeProofed ? true : false;
                    let partlyProofed = mate.isAcademyProofed || mate.isMarriageProofed || mate.isJobProofed || mate.isIncomeProofed ? true : false;
                    let noProofed = !mate.isAcademyProofed && !mate.isMarriageProofed && !mate.isJobProofed && !mate.isIncomeProofed ? true : false;
                    let meetingApproved = memberInfo.sex == "m" ? matchingArr[index].isWomanAccepted : matchingArr[index].isManAccepted;
                    let age = mate.birth.substring(2, 4);
                    let job = mate.jobName;
                    let marriage = mate.marriageStatusType == 1 ? "미혼" : mate.marriageStatusType == 2 ? "재혼(무자녀)" : mate.marriageStatusType == 3 ? "재혼(자녀O)" : "";
                    let accademy = mate.lastSchoolName + " " + mate.major;
                    console.log(" matching >> ", mate);

                    return (
                        <div className="w-334p" onClick={(ev) => { moveToMemberDetail(mate, matchingArr[index]) }}>
                            <div className={index != 0 ? "ml-2 mr-2 h-1p bg-col_button_gray" : "hidden"}></div>
                            <button className="flex flex-row h-32 w-334p hover:bg-col_button_gray active:bg-col_back_gray_light">
                                <div className="mt-5 ml-5 mr-15p">
                                    <img src={s3address + mate.profileImgUrls[0]} alt="프로파일 이미지" className="w-75p h-75p rounded-full" />
                                </div>

                                <div className="flex flex-col mt-18p">
                                    <div className="flex flex-row w-60 items-center justify-between">
                                        <div className="flex flex-row">
                                            <div className={meetingApproved ? "flex flex-col justify-center items-center w-14 h-5 rounded-2xl bg-col_bora text-white text-xs font-semibold mr-1" : "hidden"} id="meetingApproved">만남수락</div>
                                            <div className={fullProofed ? getProofStyle("full") : partlyProofed ? getProofStyle("part") : getProofStyle("no")} id="proofedType">{fullProofed ? "풀인증" : partlyProofed ? "부분인증" : "미인증"}</div>
                                        </div>
                                        <div className="text-xs">{leftDays + "일 남음"}</div>
                                    </div>
                                    <div className="flex flex-row mt-1">
                                        <div className="text-lg font-bold mr-2">{showHideName(mate.name)}</div>
                                        <div className="text-lg font-bold">{age}<span className="text-sm">년생</span></div>
                                    </div>
                                    <div className="flex flex-row mt-1">
                                        <div className="text-sm text-col_font_gray mr-2">{marriage}</div>
                                        <div className="text-sm text-col_font_gray ">{job}</div>
                                    </div>
                                    <div className="flex flex-row mt-1">
                                        <div className="text-sm text-col_font_gray mr-2">{accademy}</div>
                                    </div>
                                </div>
                            </button>
                        </div>
                    );
                }))
        }
    }

    return (<div>
        <div className="flex flex-row items-center justify-center h-55p w-334p mt-11p">
            <div className="text-lg font-bold">매칭</div>
            <button className="fixed right-3 top-5 rounded-2xl w-16 h-8 bg-yellow-300 ml-6" onClick={(ev) => {shareViaKakao(memberInfo.id+"")}}>
                <span className="text-xs">친구 추천</span>
            </button> 
        </div>
        <div>
            {
                getMatchingList(mateList, recommList)
            }
        </div>
        <div className="fixed bottom-0">
            <Footer current={2} />
        </div>
    </div>);
}



export default MatchingList;