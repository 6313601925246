import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "./common/header";
import circleCheckGray from '../../img/circle_check_gray.png';
import circleCheckPink from '../../img/circle_check_pink.png';
import arrowRight from '../../img/arrow_right.png';
import { Dialog } from "@mui/material";
import { btnContinueStyle2, btnNomalStyle2, nextBtnStyle } from "../../common/commonStyle";
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";

const TermsNConditions = () => {
    const [useConditon, setUseCondition] = useState(false);
    const [privacyConditon, setPrivacyCondition] = useState(false);
    const [marketingConditon, setMarketingCondition] = useState(false);
    const [allConditon, setAllCondition] = useState(false);

    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    useEffect(() => {
        if (privacyConditon && useConditon && marketingConditon) {
            setAllCondition(true);
        } else {
            setAllCondition(false);
        }
    }, [privacyConditon, useConditon, marketingConditon]);

    
    const agreeAll = () => {
        setAllCondition(!allConditon);
        if (allConditon) {
            setUseCondition(false);
            setPrivacyCondition(false);
            setMarketingCondition(false);
        } else {
            setUseCondition(true);
            setPrivacyCondition(true);
            setMarketingCondition(true);
        }
    };

    const handleTermsnCondition = () => {
        memberInfo.marketingAgree = marketingConditon;
        return  {ok:true, type:"db"}
    };

    const seeUseCondition = () => {

    };

    const seePrivacyCondition = () => {

    };

    const seeMarketingCondition = () => {

    };

    return (<div>
        <Header screenName="약관 동의" skipAvailable={false} />
        <div className="flex flex-col ml-5 mt-30p">
            <div className="text-xl font-bold">약관 동의</div>
            <div className="text-sm mt-10p text-col_font_gray">마이디어 약관에 동의해 주세요.</div>
        </div>
        <div className="w-96 ml-21p">
            <div className="flex flex-row mt-40p items-center justify-between bg-col_back_gray border-0 rounded-md h-45p mr-20p">
                <div className="flex flex-row" onClick={agreeAll} >
                    <img src={allConditon ? circleCheckPink : circleCheckGray} className="ml-15p" />
                    <span className="ml-10p">모든 약관에 동의합니다.</span>
                </div>
                <img src={arrowRight} className="mr-10p" onClick={agreeAll} alt="모든 약관 동의 버튼" />
            </div>
            <div className="flex flex-row mt-24p items-center justify-between rounded-md h-45p mr-20p">
                <div className="flex flex-row" onClick={(ev) => { setUseCondition(!useConditon) }}>
                    <img src={useConditon ? circleCheckPink : circleCheckGray} className="ml-15p" alt="이용약관 동의 버튼"/>
                    <span className="ml-10p">이용 약관 동의</span><span className="ml-2 text-col_main_pink">(필수)</span>
                </div>
                <a href="https://mydearyouko.blogspot.com/2022/11/mydear_9.html" target='_blank'> <img src={arrowRight} className="mr-10p" onClick={seeUseCondition} /></a>
            </div>
            <div className="flex flex-row mt-24p items-center justify-between rounded-md h-45p mr-20p">
                <div className="flex flex-row" onClick={(ev) => { setPrivacyCondition(!privacyConditon) }} >
                    <img src={privacyConditon ? circleCheckPink : circleCheckGray} className="ml-15p" />
                    <span className="ml-10p">개인정보처리방침 동의</span><span className="ml-2 text-col_main_pink">(필수)</span>
                </div>
                <a href="https://mydearyouko.blogspot.com/2022/11/mydear.html" target='_blank'> <img src={arrowRight} className="mr-10p" onClick={seePrivacyCondition} alt="개인정보 처리 동의 버튼" /></a>
            </div>
            <div className="flex flex-row mt-24p mb-52 items-center justify-between rounded-md h-45p mr-20p">
                <div className="flex flex-row" onClick={(ev) => { setMarketingCondition(!marketingConditon) }}>
                    <img src={marketingConditon ? circleCheckPink : circleCheckGray} className="ml-15p" alt="마케팅 광고 활용 동의 버튼"  />
                    <span className="ml-10p">마케팅, 광고 활용 동의</span><span className="ml-2">(선택)</span>
                </div>
                <img src={arrowRight} className="mr-10p" onClick={seeMarketingCondition} />
            </div>
            <NextBtn btnTitle="다음으로" ableCondtidion={useConditon && privacyConditon} nextScreen="selfAuth" dataHandlingFn={handleTermsnCondition} />
        </div>

    </div>);
}

export default TermsNConditions;