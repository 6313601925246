import axios from "axios";
import React, { useState, useContext, useEffect, useCallback } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { LoginInfo } from "../../common/dataType";
import background from '../../img/loading_transparent_large.png';
// import background from '../../img/mydear_landing_main.png';
import logo from '../../img/logo_mydear.png';
import MemberContext from "../context/memberContext";

const Landing = () => {
    let navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [pass, setPass] = useState("");
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const memberInfo = useContext(MemberContext);

    const { recId } = useParams<any>();
    console.log(" recId => ", recId);
    memberInfo.recId = recId ? recId : "";

    const moveToLogin = () => {
        navigate("/login");
    }

    const handleClose = () => {
        setOpen(false);
    };

    useEffect(() => {
        console.log(" recId => ", recId);
        if (recId) {
            // upload recId to server
            const updateRecCounts = async () => {
                const memberRes = await axios.get(common.SERVER_URL + "/member/" + recId);
                let mainCounts = memberRes.data.member.recCountsMain;
                const updateMember = await axios.post(common.SERVER_URL + "/member/updateMember", { id: recId, recCountsMain: mainCounts + 1 });
                console.log(" updateMember => ", updateMember.data);
            }
            updateRecCounts();
        }
    }, []);

    return (
        <div className="flex flex-col">
            <div className="flex flex-row bg-gray-100 justify-center pr-4 pl-4 pb-4">
                <div className="flex flex-col">
                    <div className="flex flex-row items-center mt-7 w-40">
                        <img src={logo} className="w-10 h-10 mr-5" />
                        <div className="text-3xl">MyDear</div>
                    </div>
                    <div className="flex flex-col justify-start w-56">
                        <div className="text-xl mt-10 font-bold">데이트에만 집중하세요</div>
                        <div className="text-xl font-bold">효율적 만남 추구</div>
                        <div className="text-3xl mt-11">마이디어</div>
                        <button className="border rounded-xl border-col_main_pink w-36 mt-12 
                    text-sm h-10 flex flex-col 
                    justify-center items-center
                     text-col_main_pink
                     hover:bg-pink-300 
                     focus:ring focus:ring-col_main_pink" onClick={moveToLogin}>마이디어 이용해보기</button>
                    </div>
                </div>
                <div className="flex flex-col">
                    <div className="h-24 lg:h-0"></div>
                    <img src={background} className="w-48 h-52 lg:mt-5 lg:w-375p lg:h-444p" />
                    <div className="h-12"></div>
                </div>
            </div>


            <div className="flex flex-row mt-7 lg:ml-5 justify-center">
                <div className="flex flex-col items-center">
                    <div className="flex flex-col items-center p-7">
                        <div className=" text-col_main_pink2 font-bold">취향 존중</div>
                        <div className="text-2xl font-bold mt-5 text-center">회원 데이터에 기반한 <br/>정교한 추천 알고리즘</div>
                        <div className="text-2xl font-bold mt-5 text-center">내 스타일 지수<br/>(My Style Index)</div>
                        <div className="flex flex-col pl-4 pt-4 items-center mt-4 mb-7">
                            마이디어가 개발한 MS Index 기반 <br/>추천 엔진으로
                            여러분들이 가장 좋아할 <br/>상대들을 우선적으로 추천해 드립니다.<br/>
                            더 이상 맞지도 않는 상대에 시간과 <br/>노력을 낭비하지 마세요.
                            인생은 짧고,<br/> 할일은 많으며 우리는 이제 피곤합니다.
                        </div>
                    </div>

                    <div className="bg-gray-100 flex flex-col items-center p-7 w-375p">
                        <div className=" text-col_main_pink2 font-bold ">코칭</div>
                        <div className="text-2xl font-bold mt-5 text-center">커플 맞춤형 코칭 <br/><span className="text-xl">(Couple Customized Coaching)</span><br/>CCC 서비스</div>
                        <div className="flex flex-col pl-4 pt-4 text-center mt-4 mb-7">
                          매칭된 커플들의 취향, 라이프스타일을 고려 <br/>최적, 최고의 데이팅 코스 추천.<br/>
                          어디서, 언제 만나서, 뭐 먹지, 뭘 하지?<br/> 무슨 애기해야하지? 고민 끝.<br/>
                          연인과 데이트에만 집중하세요.<br/> 나머지는 마이디어가 해드립니다
                        </div>
                    </div>

                    <div className="flex flex-col items-center p-7">
                        <div className=" text-col_main_pink2 font-bold ">사후관리</div>
                        <div className="text-2xl font-bold mt-5 text-center">무기한 싱글 방지<br/><span className="text-xl">(Unlimited Anti Single)</span><br/>UAS 서비스</div>
                        <div className="flex flex-col pl-4 pt-4 items-center mt-4 mb-7">
                            <div className="text-base">
                           인연을 못만났으면 만나실 때까지 <br/>찾아드립니다.
                           만났다가 헤어져서 <br/>돌아오시면 다시 찾아드립니다.<br/>
                           1:1 매칭 뿐 아니라 N:N 미팅, <br/>이벤트 등 모든 방법을 통해 <br/>싱글을 방지해 해드립니다.
                           </div>
                        </div>
                    </div>

                    <div className="bg-gray-100 flex flex-col items-center p-7 w-375p">
                        <div className=" text-col_main_pink2 font-bold">합리성</div>
                        <div className="text-2xl font-bold mt-5 text-center">"회비 없음" (No Fee)</div>
                        <div className="text-2xl font-bold mt-5 text-center">친구를 소개해주고 <br/>연인을 소개받으세요</div>
                        <div className="flex flex-col pl-4 pt-4 text-center mt-4 mb-7">
                           마이디어는 가입, 소개, 성혼비를 받지 않습니다
                            <br/>오직 서로 만남 의사가 있을때만 <br/>39,000원 결제가 전부입니다.<br/>
                            이마저도 친구나 지인을<br/> 소개해주시면 무료입니다.<br/>
                            마이디어는 합리적이고 스마트한<br/> 고객들을 위한 서비스입니다.
                        </div>
                    </div>

                    <div className="flex flex-col items-center p-7">
                    <div className=" text-col_main_pink2 font-bold">검증과 인증</div>
                    <div className="text-2xl font-bold mt-5 text-center">투명 공개 원칙<br/><span className="text-xl">(All Disclosure Principle)</span> <br/>ADP 원칙</div>
                    <div className="flex flex-col pl-4 pt-4 items-center mt-4">
                        <div className="text-base">마이디어의 모든 회원들은 철저한 <br/>검증과 인증을 거치며
                            인증 여부를 <br/>투명하게 공개합니다.
                        인증 여부를<br/> 직접 확인하시고 만남을 결정하세요.</div>
                    </div>
                    </div>

                    <div className="text-center mt-10">
                        <a href="/login" className="text-xl underline text-col_main_pink2 hover:text-blue-600 active:font-bold">
                            지금 마이디어에서 나와 맞는<br/> 데이트를 하세요</a>
                    </div>
                    <div className="bg-blue-50 flex flex-row justify-end pt-8 mt-8  pb-10 pl-5">
                        <div className="flex flex-col">
                            <div className="text-xs">마이디어</div>
                            <div className="text-xs">대표: 송해석 | 개인정보관리책임자: 송해석 | 이메일: mydearyouko@gmail.com</div>
                            <div className="text-xs">주소: 서울시 영등포구 국회대로 36길 6-1층,2층 520호</div>
                            <div className="text-xs">사업자등록번호: 234-55-00772</div>
                            <div className="text-xs">결혼중개업 등록</div>
                            <div className="text-xs">고객센터운영시간:</div>
                            <div className="text-xs">(월~금) 오전9:30 ~ 오후5:00 / (주말, 공휴일) 오전10:00 ~ 오후4:00</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
}

export default Landing;