import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation, Navigate } from "react-router-dom";
import common from "../../common/common";
import Header from "./common/header";
import { btnContinueStyle1, btnContinueStyle2, btnNomalStyle1, btnNomalStyle2, inputBoxNormalStyle, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import { birthYears, months, days, selectData, academySelector, selectDataNumber, maleSiblingSelector, femaleSiblingSelector, siblingOrderSelector, JobPositionSelector } from "../../common/dataType";
import checkGray from '../../img/check_gray.png';
import checkPink from '../../img/check_pink.png';
import circleX from '../../img/circle_x.png';
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";
import Loading from "./common/loading";

const Parent = () => {
    const [fatherEdu, setFatherEdu] = useState("");
    const [fatherSchool, setFatherSchool] = useState("");
    const [fatherJob, setFatherJob] = useState("");
    const [fatherJobPosition, setFatherJobPosition] = useState("");
    const [fatherRetired, setFatherRetired] = useState(false);
    const [fatherPassedAway, setFatherPassedAway] = useState(false);

    const [motherEdu, setMotherEdu] = useState("");
    const [motherSchool, setMotherSchool] = useState("");
    const [motherJob, setMotherJob] = useState("");
    const [motherJobPosition, setMotherJobPosition] = useState("");
    const [motherRetired, setMotherRetired] = useState(false);
    const [motherPassedAway, setMotherPassedAway] = useState(false);

    const [maleSibling, setMaleSibling] = useState("");
    const [femaleSibling, setFeMaleSibling] = useState("");
    const [siblingOrder, setSiblingOrder] = useState("");
    const [parentAsset, setParentAsset] = useState(0);
    
    const [loadingOpen, setLoadingOpen] = useState(false);
    const memberInfo = useContext(MemberContext);
    let navigate = useNavigate();

    useEffect(() => {
        if(memberInfo.father){
            setFatherEdu(memberInfo.father.lastEducation);
            setFatherSchool(memberInfo.father.schoolName);
            setFatherJob(memberInfo.father.job);
            setFatherJobPosition(memberInfo.father.jobPosition);
            setFatherRetired(memberInfo.father.isRetired);
            setFatherPassedAway(memberInfo.father.isPassedAway);
        }

        if(memberInfo.mother){
            setMotherEdu(memberInfo.mother.lastEducation);
            setMotherSchool(memberInfo.mother.schoolName);
            setMotherJob(memberInfo.mother.job);
            setMotherJobPosition(memberInfo.mother.jobPosition);
            setMotherRetired(memberInfo.mother.isRetired);
            setMotherPassedAway(memberInfo.mother.isPassedAway);
        }

        setParentAsset(memberInfo.parentAsset);
        setMaleSibling(memberInfo.sibling.substring(0,1));
        setFeMaleSibling(memberInfo.sibling.substring(1,2));
        setSiblingOrder(memberInfo.sibling.substring(2,3));

        console.log(" memberInfo.id >> ", memberInfo.id);
    }, [])

    const goToIntroduction = async () => {
        setLoadingOpen(true);
        let serverUploadResult = {ok:false, type:"none"};
        let dataToInput = {
            id:memberInfo.id,
            father:{lastEducation: fatherEdu, schoolName:fatherSchool, job:fatherJob, jobPosition: fatherJobPosition, isAlive:fatherPassedAway, isRetired:fatherRetired},
            mother:{lastEducation: motherEdu, schoolName:motherSchool, job:motherJob, jobPosition: motherJobPosition, isAlive:motherPassedAway, isRetired:motherRetired},
            siblingOrder:maleSibling+ femaleSibling+siblingOrder,
            parentAsset:parentAsset
        }
        let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", dataToInput);
        console.log("dbUpdate >> ", dbUpdate);

        setLoadingOpen(false);
        if(dbUpdate.data.ok){
            serverUploadResult.ok = true;
            serverUploadResult.type = "db";
            console.log("memberInfo >> ", memberInfo);
            if(!memberInfo.father){
                memberInfo.father = {lastEducation:"", schoolName:"",job:"", jobPosition:"", isRetired:false, isPassedAway:false }
            }
            if(!memberInfo.mother){
                memberInfo.mother = {lastEducation:"", schoolName:"",job:"", jobPosition:"", isRetired:false, isPassedAway:false }
            }

            memberInfo.father.lastEducation = dbUpdate.data.member.father.lastEducation;
            memberInfo.father.schoolName = dbUpdate.data.member.father.schoolName;
            memberInfo.father.job = dbUpdate.data.member.father.job;
            memberInfo.father.jobPosition = dbUpdate.data.member.father.lastEducation;
            memberInfo.father.isRetired = dbUpdate.data.member.father.isRetired;
            memberInfo.father.isPassedAway = dbUpdate.data.member.father.isPassedAway;

            memberInfo.mother.lastEducation = dbUpdate.data.member.mother.lastEducation;
            memberInfo.mother.schoolName = dbUpdate.data.member.mother.schoolName;
            memberInfo.mother.job = dbUpdate.data.member.mother.job;
            memberInfo.mother.jobPosition = dbUpdate.data.member.mother.lastEducation;
            memberInfo.mother.isRetired = dbUpdate.data.member.mother.isRetired;
            memberInfo.mother.isPassedAway = dbUpdate.data.member.mother.isPassedAway;
            
            memberInfo.parentAsset = dbUpdate.data.member.parentAsset;
            memberInfo.sibling = dbUpdate.data.member.siblingOrder;

        }
        return serverUploadResult;
    };

    return (<div>
        <Header screenName="가족 관계" skipAvailable={true} nextFn={()=>{navigate("/introduction")}}/>
        <div className="ml-5 mr-5 mt-30p w-334p mb-55p">
            <div className="text-xl">아버지</div>
            <div className="text-sm text-col_font_gray mt-10p">부친에 대해 알려주세요</div>
            <div className="text-sm mt-40p">최종 학력</div>
            <select onChange={(ev) => { setFatherEdu(ev.target.value) }} className={inputBoxNormalStyle + " w-334p"} value={fatherEdu}>
            {
                academySelector.map((eachYear: selectDataNumber) => {
                    return <option value={eachYear.label}>{eachYear.label}</option>
                })
            }
            </select>
            <div className="text-sm mt-30p">학교</div>
            <input type="text" className={inputBoxNormalStyle + "  w-334p"} placeholder="학교 이름을 입력해 주세요" onChange={(ev) => setFatherSchool(ev.target.value)} defaultValue={memberInfo.father?.schoolName ? memberInfo.father?.schoolName : "" } />
            <div className="text-sm mt-30p">직업</div>
            <input type="text" className={inputBoxNormalStyle + "  w-334p"} placeholder="직업을 입력해 주세요" onChange={(ev) => setFatherJob(ev.target.value)} defaultValue={memberInfo.father?.job ? memberInfo.father?.job : ""} />
            <div className="text-sm mt-30p">직위</div>
            <select onChange={(ev) => { setFatherJobPosition(ev.target.value) }} className={inputBoxNormalStyle + " w-334p"} defaultValue={memberInfo.father?.jobPosition ? memberInfo.father?.jobPosition : ""}>
                    {
                        JobPositionSelector.map((eachPosition: selectDataNumber) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
            </select>

            <div className="text-sm mt-30p">은퇴</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={fatherRetired == false ? smallBtnSelectedStyle : smallBtnNormalStyle} onClick={(ev) => setFatherRetired(false)}><span className={fatherRetired == false ? "text-col_main_pink" : "text-col_button_gray_text"}>재직</span></button>
                <button className={fatherRetired ? smallBtnSelectedStyle + " ml-10p" : smallBtnNormalStyle + " ml-10p"} onClick={(ev) => setFatherRetired(true)}><span className={fatherRetired == true ? "text-col_main_pink" : "text-col_button_gray_text"}>은퇴</span></button>
            </div>

            <div className="text-sm mt-30p">작고</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={fatherPassedAway ? smallBtnSelectedStyle+" w-334p" : smallBtnNormalStyle +" w-334p"} onClick={(ev) => setFatherPassedAway(!fatherPassedAway)}><span className={fatherPassedAway == true ? "text-col_main_pink" : "text-col_button_gray_text"}>작고</span></button>
            </div>


            <div className="text-xl mt-50p">어머니</div>
            <div className="text-sm text-col_font_gray mt-10p">모친에 대해 알려주세요</div>
            <div className="text-sm mt-40p">최종 학력</div>
            <select onChange={(ev) => { setMotherEdu(ev.target.value) }} className={inputBoxNormalStyle + " w-334p"} value={motherEdu}>
            {
                academySelector.map((eachYear: selectDataNumber) => {
                    return <option value={eachYear.label}>{eachYear.label}</option>
                })
            }
            </select>
            <div className="text-sm mt-30p">학교</div>
            <input type="text" className={inputBoxNormalStyle + "  w-334p"} placeholder="학교 이름을 입력해 주세요" onChange={(ev) => setMotherSchool(ev.target.value)} defaultValue={memberInfo.mother?.schoolName ? memberInfo.mother?.schoolName: ""} />
            <div className="text-sm mt-30p">직업</div>
            <input type="text" className={inputBoxNormalStyle + "  w-334p"} placeholder="직업을 입력해 주세요" onChange={(ev) => setMotherJob(ev.target.value)} defaultValue={memberInfo.mother?.job ? memberInfo.mother?.job : "" } />
            <div className="text-sm mt-30p">직위</div>
            <select onChange={(ev) => { setMotherJobPosition(ev.target.value) }} className={inputBoxNormalStyle + " w-334p"} defaultValue={memberInfo.mother?.jobPosition ? memberInfo.mother?.jobPosition : ""}>
                    {
                        JobPositionSelector.map((eachPosition: selectDataNumber) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
            </select>
            <div className="text-sm mt-30p">은퇴</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={motherRetired == false ? smallBtnSelectedStyle : smallBtnNormalStyle} onClick={(ev) => setMotherRetired(false)}><span className={motherRetired == false ? "text-col_main_pink" : "text-col_button_gray_text"}>재직</span></button>
                <button className={motherRetired ? smallBtnSelectedStyle + " ml-10p" : smallBtnNormalStyle + " ml-10p"} onClick={(ev) => setMotherRetired(true)}><span className={motherRetired == true ? "text-col_main_pink" : "text-col_button_gray_text"}>은퇴</span></button>
            </div>

            <div className="text-sm mt-30p">작고</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={motherPassedAway ? smallBtnSelectedStyle+" w-334p" : smallBtnNormalStyle +" w-334p"} onClick={(ev) => setMotherPassedAway(!motherPassedAway)}><span className={motherPassedAway == true ? "text-col_main_pink" : "text-col_button_gray_text"}>작고</span></button>
            </div>

            <div className="text-xl mt-50p">부모님 보유재산</div>
            <div className="text-sm text-col_font_gray mt-10p">대략적인 부모님 보유재산을 알려주세요</div>
            <div className="flex flex-row items-center">
            <input type="number" className={inputBoxNormalStyle + "  w-52"} placeholder="ex) 20억 -> 200000" onChange={(ev) => setParentAsset(Number(ev.target.value))} defaultValue={memberInfo.parentAsset == 0 ? undefined: memberInfo.parentAsset} />
            <span className="ml-3 w-12">만원</span>
            </div>
            
            <div className="text-xl mt-50p">형제, 자매</div>
            <div className="text-sm text-col_font_gray mt-10p">형제, 자매관계를 알려주세요</div>
            <div className="flex flex-row items-center mb-7">
                <select onChange={(ev) => { setMaleSibling(ev.target.value)}} className={inputBoxNormalStyle +" w-20"}  value={maleSibling}>
                    {
                        maleSiblingSelector.map((eachYear: selectData) => {
                            return <option value={eachYear.value}>{eachYear.label}</option>
                        })
                    }
                </select>
                <select onChange={(ev) => { setFeMaleSibling(ev.target.value)}} className={inputBoxNormalStyle +" w-20 ml-11p"}  value={femaleSibling} >
                    {
                        femaleSiblingSelector.map((eachMonth: selectData) => {
                            return <option value={eachMonth.value} >{eachMonth.label}</option>
                        })
                    }
                </select>
                <select onChange={(ev) => { setSiblingOrder(ev.target.value)}} className={inputBoxNormalStyle +" w-20 ml-11p"}  value={siblingOrder} >
                    {
                        siblingOrderSelector.map((eachDay: selectData) => {
                            return <option value={eachDay.value}>{eachDay.label}</option>
                        })
                    }
                </select>
            </div>
            <NextBtn btnTitle="다음으로" ableCondtidion={true} nextScreen="introduction" dataHandlingFn={goToIntroduction} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>
    </div>);
}

export default Parent;