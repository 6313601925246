import React, { useEffect, useState } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { LoadingProps } from "../../../common/dataType";
import { Dialog } from "@mui/material";

const Loading = (props : LoadingProps) => {
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        props.closeFire();
    };
    useEffect(() => {
        setOpen(props.open);
    },[])
    
    return (
        <Dialog open={props.open} onClose={handleClose} className="">

                <Box sx={{ display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent:'center', width:'200px', height:'130px' }}>
                    <div className="mb-2">처리중 입니다....</div>
                    <CircularProgress />
                </Box>
           
        </Dialog>
      );
}


export default Loading;