import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import Header from "./common/header";
import { btnContinueStyle1, btnContinueStyle2, btnNomalStyle1, btnNomalStyle2, inputBoxNormalStyle, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import { birthYears, months, days, selectData } from "../../common/dataType";
import checkGray from '../../img/check_gray.png';
import checkPink from '../../img/check_pink.png';
import circleX from '../../img/circle_x.png';
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";
import { getUserInfo } from "./common/commonMethod";
import Loading from "./common/loading";
import { SimpleModal } from "./common/modal";

const SelfAuth = () => {
    const [name, setName] = useState("");  // 이름

    const [mobileNum, setMobileNum] = useState("");    // 전화번호
    const [sex, setSex] = useState(""); 

    const [birthYear, setBirthYear] = useState("1984");
    const [birthMonth, setBirthMonth] = useState("");
    const [birthDay, setBirthDay] = useState("");
    const [authNumber, setAuthNumber] = useState("");
    const [givenAuthNumber, setGivenAuthNumber] = useState("1234");
    const [sendAuthBtn, setSendAuthBtn] = useState(false);
    const [authMatched, setAuthMatched] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [openAlert, setOpenAlert] = useState(false); 
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    useEffect(()=>{
      if(name&& mobileNum.length > 10 && sex && birthYear && birthMonth && birthDay){
        setSendAuthBtn(true);
    }
    },[mobileNum])

    useEffect(()=>{
        if(authNumber == givenAuthNumber){
            setAuthMatched(true);
        }else{
            setAuthMatched(false);

        }
      },[authNumber]);

    useEffect(() => {
        // getUserInfo(memberInfo.id).then(member =>{
        //     memberInfo.name = member.name;
        //     memberInfo.mobile = member.mobile;
        //     memberInfo.sex = member.sex;
        //     memberInfo.birth = member.birth;
        //     setName(memberInfo.name)
        //     setMobileNum(memberInfo.mobile);
        //     setSex(memberInfo.sex);
        //     setBirthYear(memberInfo.birth.substring(0,4));
        //     setBirthMonth(memberInfo.birth.substring(4,6));
        //     setBirthDay(memberInfo.birth.substring(6,8));
        // })
        setName(memberInfo.name)
        setMobileNum(memberInfo.mobile);
        setSex(memberInfo.sex);
        setBirthYear(memberInfo.birth.substring(0,4));
        setBirthMonth(memberInfo.birth.substring(4,6));
        setBirthDay(memberInfo.birth.substring(6,8));
    },[]);

    const goToSignup = async () => {
        let serverUploadResult = {ok:false, type:"none"};
        if(authMatched){
            setLoadingOpen(true);
            let signupInfo = {
                name:name,
                mobile:mobileNum,
                birth:birthYear+birthMonth+birthDay,
                sex:sex,
                marketingAgree:memberInfo.marketingAgree,
            }
            let result = null;
            
            if(memberInfo.id != 0){
                let reSignupInfo = {...signupInfo, id:memberInfo.id};
                result = await axios.post(common.SERVER_URL+"/member/updateMember", reSignupInfo);
            }else{ 
                result = await axios.post(common.SERVER_URL+"/member/addMember", signupInfo);
            }
            
            setLoadingOpen(false);
            console.log(" result selfAuth >>> ", result);
            if(result.data.ok){
                memberInfo.id = result.data.member.id;
                memberInfo.mobile = result.data.member.mobile;
                memberInfo.name = result.data.member.name;
                memberInfo.sex = result.data.member.sex;
                memberInfo.birth = result.data.member.birth;
                serverUploadResult.ok = true;
                serverUploadResult.type="db";
            }
        }
        return serverUploadResult;
    };

    const requestAuthNum = async () => {
        // 뿌리오 문자 로직 
        setOpenAlert(true);
    };

    const clickX = () => {
        if(!authMatched){
            setAuthNumber("");
        }
    };

    return(<div>
        <Header screenName="기본 정보" skipAvailable={memberInfo.mobile && memberInfo.name ? true : false} nextFn={() =>{navigate("/signup");}} />
        <div className="ml-5 mr-5 mt-30p">
            <div className="text-sm">성명</div>
            <div className="flex flex-row items-center">
                <input type="text" className={inputBoxNormalStyle +" w-56 ml-11p"} placeholder="실명" onChange={(ev) => setName(ev.target.value)} defaultValue={name}/>
            </div>
            <div className="text-sm mt-30p">성별</div>
            <div className="flex flex-row items-center mt-10p">
                <button className={sex == "m" ? smallBtnSelectedStyle : smallBtnNormalStyle } onClick={(ev) => setSex("m")}><span className={sex == "m" ? "text-col_main_pink" : "text-col_button_gray_text"}>남성</span></button>
                <button className={sex == "f" ? smallBtnSelectedStyle + " ml-10p" : smallBtnNormalStyle + " ml-10p" }  onClick={(ev) => setSex("f")}><span className={sex == "f" ? "text-col_main_pink" : "text-col_button_gray_text"}>여성</span></button>
            </div>
            <div className="text-sm mt-30p">생년월일</div>
            <div className="flex flex-row items-center">
                <select onChange={(ev) => { setBirthYear(ev.target.value) }} className={inputBoxNormalStyle +" w-32"}  value={birthYear}>
                    {
                        birthYears.map((eachYear: selectData) => {
                            return <option value={eachYear.value}>{eachYear.label}</option>
                        })
                    }
                </select>
                <select onChange={(ev) => { setBirthMonth(ev.target.value) }} className={inputBoxNormalStyle +" w-20 ml-11p"}  value={birthMonth} >
                    {
                        months.map((eachMonth: selectData) => {
                            return <option value={eachMonth.value} >{eachMonth.label}</option>
                        })
                    }
                </select>
                <select onChange={(ev) => { setBirthDay(ev.target.value) }} className={inputBoxNormalStyle +" w-20 ml-11p"}  value={birthDay} >
                    {
                        days.map((eachDay: selectData) => {
                            return <option value={eachDay.value}>{eachDay.label}</option>
                        })
                    }
                </select>
            </div>
            <div className="text-sm mt-30p">전화번호('-'제외)</div>
            <div className="flex flex-row items-center mt-1.5">
                <input type="number" className={inputBoxNormalStyle +" w-334p"} placeholder="ex) 01012345678" onChange={(ev) => setMobileNum(ev.target.value)} defaultValue={mobileNum}/>
            </div>
            <div className={sendAuthBtn ? btnContinueStyle1+" mt-21p" : btnNomalStyle1+" mt-21p"} onClick={requestAuthNum}><span className=" text-col_button_gray_text text-base">인증번호 요청</span></div>
            
            <div className="text-sm mt-30p">인증번호</div>
            <div className="flex flex-row items-center mt-1.5 mb-20">
                <input type="number" className={inputBoxNormalStyle +" w-314p"} placeholder="인증번호 입력" onChange={(ev) => setAuthNumber(ev.target.value)} value={authNumber}/>
                <img src={authNumber.length == 0 ? checkGray : authMatched ? checkPink : circleX} onClick={clickX} />
            </div>
            {/* <div className={authMatched ? nextBtnStyle+" mt-20" : btnNomalStyle2+" mt-20"} onClick={goToSignup}><span className=" text-white text-base">다음으로</span></div> */}
            <NextBtn btnTitle={"다음으로"} ableCondtidion={ authMatched ? true: false} nextScreen={"signup"} dataHandlingFn={goToSignup} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
            <SimpleModal title="임시인증번호" message="(카카오 인증 서비스와 협약 전 관계로 임시인증 번호를 보내드립니다) 임시인증번호 1234를 입력해 주세요." btn1="확인" type="simple" open={openAlert}  closeFire={() =>{setOpenAlert(false)}} />
        </div> 
    </div>);
}

export default SelfAuth;