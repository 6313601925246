import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common, { s3address } from "../../common/common";
import { LoginInfo } from "../../common/dataType";
import Header from "./common/header";
import profileBackLarge from '../../img/profile_img_large.png';
import profileBackSmall from '../../img/profile_img_small.png';
import { btnContinueStyle2, btnNomalStyle2 } from "../../common/commonStyle";
import MemberContext from "../context/memberContext";
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Photo = () => {
    const [profileImgFiles, setProfileImgFiles] = useState<Array<any>>([]);
    const [profileImgSrc, setProfileImgSrc] = useState<Array<string>>([]);
    const [fileNames, setFileNames] = useState<Array<string>>([]);
    const [registeredProfileImgSrcs, setRegisteredProfileImgSrcs] = useState<Array<string>>([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    
    useEffect(() => {
        const getImgURLs = async () => {
            let result = await axios.get(common.SERVER_URL + "/member/"+memberInfo.id);
            console.log(" result member >> ", result.data.member);

            if(result.data.member){
                let urls = result.data.member.profileImgUrls;
                console.log("urls >> ", urls);
                let finalUrls = urls.map((eachUrl:string)=>{
                    return s3address+eachUrl;
                });
                setProfileImgSrc(finalUrls);
            }else{

            }
        }; 
        getImgURLs();
    },[]);

    const handlePhoto = async () => {
        let serverResult = {ok:false, type:'none'};
        setLoadingOpen(true);
        let formData = new FormData();
        let fileNames = [];
        let lastModifieds = "";
        for (let i = 0; i < profileImgFiles.length; i++) {
            formData.append("files", profileImgFiles[i]);
            let fileName = "profileImg/" + profileImgFiles[i].lastModified +"_"+memberInfo.id;  // DB에 들어가는 FileName
            let trimedFileName = fileName.replace(/ /g, "");
            fileNames.push(trimedFileName);
            lastModifieds = lastModifieds + profileImgFiles[i].lastModified + "_"+memberInfo.id+"#";
        }

        formData.append("filePath", "profileImg/");
        formData.append("lastModifieds", lastModifieds);

        let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
        let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", {id: memberInfo.id ,profileImgUrls:fileNames});
        setLoadingOpen(false);
        console.log(" s3Result >> ", s3Result);
        console.log(" dbUpdate >> ", dbUpdate);
        
        if (s3Result.status == 201 && dbUpdate.data.ok) {
            serverResult.ok = true;
            serverResult.type = "file";
        } else {
            console.log(" error >> ", s3Result.statusText);
        }
        return serverResult;
    }

    const handleImgUpload = (event: any) => {
        let imgs: Array<any> = event.target.files;
        console.log("imgs >> ", imgs);
        setProfileImgFiles(imgs);
        let urlImgs: Array<string> = [];
        for (let i = 0; i < imgs.length; i++) {
            urlImgs.push(URL.createObjectURL(imgs[i]));
        }
        console.log(" urlImgs >>> ", urlImgs);
        setProfileImgSrc(urlImgs);
    };


    return (<div>
        <Header screenName="프로필 사진" skipAvailable={memberInfo.profileImgUrls.length > 0 ? true : false} nextFn={() =>{navigate("/video");}} />

        <div className="ml-5 mr-5 mt-30p" >
            <label htmlFor="file-input" className="w-334p">
                <div className="w-334p h-334p flex flex-col items-center justify-center bg-col_button_gray">
                    <img src={profileImgSrc.length == 0 ? profileBackLarge : profileImgSrc[0]} className="w-334p h-334p" />
                </div>
                <div className="mt-15p flex flex-row">
                    <div className="w-72.25p h-72.25p bg-col_button_gray flex items-center justify-center mr-15p"><img src={profileImgSrc[1] ? profileImgSrc[1] : profileBackSmall} className="w-72.25p h-72.25p" /></div>
                    <div className="w-72.25p h-72.25p bg-col_button_gray flex items-center justify-center mr-15p"><img src={profileImgSrc[2] ? profileImgSrc[2] : profileBackSmall} className="w-72.25p h-72.25p" /></div>
                    <div className="w-72.25p h-72.25p bg-col_button_gray flex items-center justify-center mr-15p"><img src={profileImgSrc[3] ? profileImgSrc[3] : profileBackSmall} className="w-72.25p h-72.25p" /></div>
                    <div className="w-72.25p h-72.25p bg-col_button_gray flex items-center justify-center "><img src={profileImgSrc[4] ? profileImgSrc[4] : profileBackSmall} className="w-72.25p h-72.25p" /></div>
                </div>
            </label>
            <div className="h-20"></div>
        {/* <div className={profileImgFiles.length > 0 ? btnContinueStyle2 + " ml-5 mr-5 mt-20" : btnNomalStyle2 + " ml-5 mr-5 mt-20"} onClick={handlePhoto}><span className=" text-white text-base">다음으로</span></div> */}
        <NextBtn btnTitle="다음으로" ableCondtidion={profileImgFiles.length > 0 ? true :false} nextScreen="video" dataHandlingFn={handlePhoto} />
        </div>

        <input type="file" id="file-input" className="" onChange={handleImgUpload} accept="image/*" multiple hidden />

        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default Photo;