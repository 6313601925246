import React, { useState, useContext, useEffect, useCallback } from "react";
import backArrow from '../../../img/back_arrow.png';
import MemberContext from "../../context/memberContext";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { HeaderProps, NextBtnProps } from "../../../common/dataType";
import { btnNomalStyle2, btnProofDocUpload, nextBtnStyle } from "../../../common/commonStyle";
import fileUpload from '../../img/file_upload_symbol.png';

const NextBtn = (props : NextBtnProps) => {
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    const goBack = () => {
        navigate(-1);
    };

    const goNext = async () => {
        if(props.ableCondtidion){
           let result : any = await props.dataHandlingFn();
           console.log("result >> ", result);
           if(result.ok){
               navigate("/"+props.nextScreen)
           }
        }
    };

    return (<div className="mb-5">
            <button className={props.ableCondtidion ? nextBtnStyle : btnNomalStyle2} 
            onClick={goNext}>
                <span className=" text-white text-base">{props.btnTitle}</span>
            </button>
    </div>)
}

export default NextBtn;