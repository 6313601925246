import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { academySelector, SchoolInfo, selectDataNumber } from "../../common/dataType";
import MemberContext from "../context/memberContext";
import { btnContinueStyle2, btnNomalStyle2, btnProofDocUpload, descriptionSmallText, inputBoxNormalStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import Header from "./common/header";
import { SimpleModal } from "./common/modal";
import fileUpload from '../../img/file_upload_symbol.png';
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Academy = () => {

    const [academy, setAcademy] = useState<string>("");
    const [school, setSchool] = useState<string>("");
    const [major, setMajor] = useState<string>("");
    const [schoolSelector, setSchoolSelector] = useState<SchoolInfo[]>([]);
    const [schoolFiles, setSchoolFiles] = useState<Array<any>>([]);
    const [uploadfileNames, setUploadFileNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [loadingOpen, setLoadingOpen] = useState(false);

    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    const reg = /[^\w\sㄱ-힣]|[\_]/g;

    useEffect(() => {
        if (memberInfo.id == 0) {
            navigate('/login');
        } else {
            setAcademy(memberInfo.lastEducation);
            setSchool(memberInfo.lastSchoolName);
            setMajor(memberInfo.major);
            setUploadFileNames(memberInfo.schoolProofDocsName);
        }
    }, [])

    useEffect(() => {
        console.log(" school >> ", school);
        console.log(" major >> ", major);
        console.log(" academy >> ", academy);
    }, [school, major]);

    const goToJob = async () => {
        let serverResult = {ok:false, type:"none"};
        if (academy != null && school != null) {
            setLoadingOpen(true);
            if (schoolFiles.length == 0) {
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", { id: memberInfo.id, lastEducation: academy, lastSchoolName: school, major: major, isAcademyProofed: memberInfo.schoolProofDocsName.length > 0 ? true: false});
                setLoadingOpen(false);
                if (dbUpdate.data.ok) {
                    memberInfo.lastEducation = academy;
                    memberInfo.lastSchoolName = school;
                    memberInfo.major = major;

                    serverResult.ok=true;
                    serverResult.type="db";
                } else {
                    setModalMessage("학력 정보 입력 오류");
                    setOpen(true);
                }
            } else {
                let formData = new FormData();
                let fileNames = [];
                let lastModifieds = "";
                for (let i = 0; i < schoolFiles.length; i++) {
                    formData.append("files", schoolFiles[i]);
                    let fileName = "proof/academy/" + schoolFiles[i].lastModified + "_" + memberInfo.id+"_"+schoolFiles[i].name;  // DB에 들어가는 FileName
                    let trimedFileName = fileName.replace(/ /g, "");
                    fileNames.push(trimedFileName);
                    lastModifieds = lastModifieds + schoolFiles[i].lastModified + "_" + memberInfo.id +"_"+schoolFiles[i].name+"#";
                }
                formData.append("filePath", "proof/academy/");
                formData.append("lastModifieds", lastModifieds);
                let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", { id: memberInfo.id, academyProofUrl: fileNames, lastEducation: academy, lastSchoolName: school, major: major, isAcademyProofed:true });
                setLoadingOpen(false);
                console.log(" s3Result >> ", s3Result);
                console.log(" dbUpdate >> ", dbUpdate);

                if (dbUpdate.data.ok && s3Result.status == 201) {
                    memberInfo.lastEducation = academy;
                    memberInfo.lastSchoolName = school;
                    memberInfo.major = major;
                    memberInfo.schoolProofDocsName = uploadfileNames;
                    serverResult.ok=true;
                    serverResult.type="file";
                } else {
                    if (s3Result.status != 201) {
                        setModalMessage("파일 업로드 오류");
                    } else if (dbUpdate.data.ok != true) {
                        setModalMessage("DB 입력 오류");
                    }
                    setOpen(true);
                    console.log(" error >> ", s3Result.statusText);
                }
            }
         
        } else {
            setModalMessage("최종 학력과 학교를 입력하세요.");
            setOpen(true);
        }
        return serverResult;
    }

    const handleUploadDoc = (ev: any) => {
        let docs: Array<any> = ev.target.files;
        console.log(" docs >> ", docs);
        setSchoolFiles(docs);
        let urlDocs: Array<string> = [];
        let fnames: Array<string> = [];
        for (let i = 0; i < docs.length; i++) {
            urlDocs.push(URL.createObjectURL(docs[i]));
            fnames.push(docs[i].name.replaceAll(" ",""));
        }
        console.log(" urlDocs >>> ", urlDocs);
        console.log(" fnames >>> ", fnames);
        setUploadFileNames(fnames);
    }

    const getSchoolInfo = async (searchSchoolName: string) => {
        console.log(" searchSchoolName >> ", searchSchoolName);
        if (reg.test(searchSchoolName)) {
            setSchoolSelector([]);
            return;
        }
        if (searchSchoolName.length > 1) {
            let schoolResult = await axios.get(common.SERVER_URL + "/common/findSchool/" + searchSchoolName);
            console.log(" searchSchoolName >> ", schoolResult.data.schoolList);
            setSchoolSelector(schoolResult.data.schoolList);
        } else if (searchSchoolName.length == 0) {
            setSchoolSelector([]);
        }

    };

    return (<div>
        <Header screenName="학력" skipAvailable={memberInfo.lastEducation && memberInfo.lastSchoolName ? true : false} nextFn={() =>{navigate("/job");}}  />
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">최종 학력</div>
            <div className={descriptionSmallText}>최종 학력을 입력해 주세요</div>

            <div className="text-sm mt-40p">최종 학력</div>
            <div className="flex flex-row items-center mt-10p">
                <select onChange={(ev) => { setAcademy(ev.target.value) }} className={inputBoxNormalStyle + " w-334p mt-1"} defaultValue={memberInfo.lastEducation} >
                    {
                        academySelector.map((eachReligion: selectDataNumber) => {
                            return <option value={eachReligion.label}>{eachReligion.label}</option>
                        })
                    }
                </select>
            </div>
            <div className="text-sm mt-40p">학교</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="search" className={inputBoxNormalStyle + " w-24"} placeholder="학교 검색" onChange={(ev) => getSchoolInfo(ev.target.value)} defaultValue={memberInfo.lastSchoolName} />
                <select onChange={(ev) => { setSchool(ev.target.value) }} className={inputBoxNormalStyle + " w-60 ml-2"}>
                    {
                        schoolSelector.map((eachSchool: SchoolInfo) => {
                            let seperator = eachSchool.gubun ? " / " : "";
                            return <option value={eachSchool.name} className="text-xs">{eachSchool.name + seperator + eachSchool.gubun + seperator + eachSchool.type}</option>
                        })
                    }
                </select>
            </div>
            {/* <div className={ schoolSelector.length > 0 && schoolSelector[0].name=="검색결과가 없습니다"  ? "flex flex-row items-center mt-2 ml-1" : "hidden"  }> */}
            <div className="flex flex-row items-center mt-2 ml-1">
                <span className="text-sm w-20">직접입력</span><input type="text" className={inputBoxNormalStyle + " w-72"} placeholder="(검색에 없는 경우) 학교 이름을 직접 입력해 주세요" onChange={(ev) => setSchool(ev.target.value)} defaultValue={memberInfo.lastSchoolName} />
            </div>

            <div className="text-sm mt-40p">전공</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="text" className={inputBoxNormalStyle + " w-334p"} placeholder="전공을 입력해 주세요" onChange={(ev) => setMajor(ev.target.value)} defaultValue={memberInfo.major} />
            </div>
            <div className="mt-8 flex flex-col mb-16">
                <div className="flex flex-row justify-between mb-15p">
                    <div className="text-sm">증명서 첨부</div>
                    <div className="text-sm text-col_font_gray">인증해서 매칭 확률을 높혀 보세요</div>
                </div>
                <label htmlFor="file-input" className="w-334p">
                    <div className={btnProofDocUpload} >
                        <img src={fileUpload} alt="학력 증명서 파일 업로드 버튼" /><span className=" text-white text-base ml-1.5">증명서 첨부</span>
                    </div>
                </label>
                <div className="text-sm text-col_font_gray mt-2 ml-2">졸업증명서</div>
                <a href="https://www.webminwon.com" target='_blank'><span className="text-sm text-col_font_gray ml-2 underline">증명서 발급: https://www.webminwon.com </span></a>
                <input type="file" id="file-input" className="" onChange={handleUploadDoc} multiple hidden />

                <div className={uploadfileNames.length > 0 ? "text-sm text-col_font_gray mt-6" : "hidden"}>* 첨부된 파일  ({uploadfileNames.length}개) </div>
                {
                    uploadfileNames.map((eachFileName: string) => {
                        return <div className="text-sm text-col_font_gray">{eachFileName}</div>
                    })
                }
            </div>
            {/* <div className={academy && school ? btnContinueStyle2 + " mt-16" : btnNomalStyle2 + " mt-16"} onClick={goToJob}><span className=" text-white text-base">다음으로</span></div> */}
            <NextBtn  btnTitle="다음으로" ableCondtidion={academy && school ? true : false} nextScreen="job" dataHandlingFn={goToJob} />
        </div>
        <SimpleModal title="업로드 에러" message={modalMessage} btn1="확인" type="simple" closeFire={() => { setOpen(false) }} open={open} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}


export default Academy;