import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { academySelector, CompanyInfo, SchoolInfo, selectDataNumber, workTypeSelector, selectDataCode, JobPositionSelector } from "../../common/dataType";
import MemberContext from "../context/memberContext";
import { btnContinueStyle2, btnNomalStyle2, btnProofDocUpload, descriptionSmallText, inputBoxNormalStyle, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import Header from "./common/header";
import { SimpleModal } from "./common/modal";
import fileUpload from '../../img/file_upload_symbol.png';
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Job = () => {

    const [workPlaceType, setWorkPlaceType] = useState<string>("");
    const [workPlaceName, setWorkPlaceName] = useState<string>("");
    const [jobName, setJobName] = useState<string>("");
    const [jobPosition, setJobPosition] = useState<string>("");
    const [jobDescription, setJobDescription] = useState<string>("");
    const [companySelector, setCompanySelector] = useState<CompanyInfo[]>([]);
    const [schoolFiles, setSchoolFiles] = useState<Array<any>>([]);
    const [uploadfileNames, setUploadFileNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");

    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    const reg = /[^\w\sㄱ-힣]|[\_]/g;

    useEffect(()=>{
        if(memberInfo.id == 0){
            navigate('/login');
        }else{
            setWorkPlaceType(memberInfo.workPlaceType);
            setWorkPlaceName(memberInfo.workPlaceName);
            setJobName(memberInfo.jobName);
            setJobDescription(memberInfo.jobDescription);
            setJobPosition(memberInfo.jobPosition);
            setUploadFileNames(memberInfo.jobProofDocsName);
        }
    },[])

    useEffect(() => {
        console.log(" school >> ", jobName);
        console.log(" major >> ", jobDescription);
        console.log(" academy >> ", workPlaceType);
    },[jobName, jobDescription]);

    const goToJob = async () => {
        let result = {ok:false, type:"none" };
    
        if(workPlaceType != null && jobName != null){
            setLoadingOpen(true);
            let jobInfoToUpload = {
                id:memberInfo.id,
                workPlaceName:workPlaceName,
                workPlaceType:workPlaceType,
                jobName:jobName,
                jobDescription:jobDescription,
                jobPosition:jobPosition,
                // jobProofUrl:new Array<String>(),
                isJobProofed: memberInfo.jobProofDocsName.length > 0 ? true: false,
            }
            if(schoolFiles.length == 0){
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", jobInfoToUpload);
                setLoadingOpen(false);
                if(dbUpdate.data.ok){
                    memberInfo.workPlaceType = workPlaceType;
                    memberInfo.workPlaceName = workPlaceName;
                    memberInfo.jobName = jobName;
                    memberInfo.jobDescription = jobDescription;
                    memberInfo.jobPosition = jobPosition;
                    result.ok = true;
                    result.type="db";
                    navigate('/job'); 
                }else{
                    setModalMessage("직업 정보 입력 오류");
                    setOpen(true);
                }
            }else{
                let formData = new FormData();
                let fileNames = [];
                let lastModifieds = "";
                for (let i = 0; i < schoolFiles.length; i++) {
                    formData.append("files", schoolFiles[i]);
                    let fileName = "proof/job/" + schoolFiles[i].lastModified +"_"+memberInfo.id+"_"+schoolFiles[i].name;  // DB에 들어가는 FileName
                    let trimedFileName = fileName.replace(/ /g, "");
                    fileNames.push(trimedFileName);
                    lastModifieds = lastModifieds + schoolFiles[i].lastModified + "_"+memberInfo.id+"_"+schoolFiles[i].name+"#";
                }
                formData.append("filePath", "proof/job/");
                formData.append("lastModifieds", lastModifieds.replace(/ /g, ""));
                let jobInfoToUploadWithFile = { ...jobInfoToUpload , jobProofUrl: fileNames}
                jobInfoToUploadWithFile.isJobProofed = true;
                let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
                let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", jobInfoToUploadWithFile);
                console.log(" s3Result >> ", s3Result);
                console.log(" dbUpdate >> ", dbUpdate);
                setLoadingOpen(false);
                if (dbUpdate.data.ok && s3Result.status == 201) {
                    memberInfo.workPlaceType = workPlaceType;
                    memberInfo.workPlaceName = workPlaceName;
                    memberInfo.jobName = jobName;
                    memberInfo.jobDescription = jobDescription;
                    memberInfo.jobPosition = jobPosition;
                    memberInfo.jobProofDocsName = uploadfileNames;
                    result.ok = true;
                    result.type = "file";
                    navigate('/job');
                } else {
                    if(s3Result.status != 201){
                        setModalMessage("파일 업로드 오류");
                    }else if(dbUpdate.data.ok != true){
                        setModalMessage("DB 입력 오류");
                    }
                    setOpen(true);
                    console.log(" error >> ", s3Result.statusText);
                }
            }
        }else{
            setModalMessage("직장 타입과 직업명을 입력해 주세요");
            setOpen(true);
        }
        return result;
    }

    const handleUploadDoc = (ev:any) => {
        let docs: Array<any> = ev.target.files;
        console.log(" docs >> ", docs);
        setSchoolFiles(docs);
        let urlDocs: Array<string> = [];
        let fnames: Array<string> = [];
        for (let i = 0; i < docs.length; i++) {
            urlDocs.push(URL.createObjectURL(docs[i]));
            fnames.push(docs[i].name.replaceAll(" ",""));
        }
        console.log(" urlDocs >>> ", urlDocs);
        console.log(" fnames >>> ", fnames);
        setUploadFileNames(fnames);
    }

    const getCompanyInfo = async (searchCompanyName:string) => {
        console.log(" searchCompanyName >> ",searchCompanyName);
        if(reg.test(searchCompanyName)){
            setCompanySelector([]);
            return;
        }
        if(searchCompanyName.length > 1){
            let companyResult = await axios.get(common.SERVER_URL+"/common/findCompany/"+searchCompanyName);
            console.log(" searchCompany List >> ",companyResult.data.companyList);
            if(companyResult.data.companyList){
                setCompanySelector(companyResult.data.companyList);
            }else{
                let noResult : CompanyInfo[] = [{name:"검색결과가 없습니다", corpNo:"", type:"", avgSalary:0, avgWorkingTerm:0, avgEmployeeCnt:0}]
                setCompanySelector(noResult);
            }
        }else if(searchCompanyName.length == 0){
            setCompanySelector([]);
        }

    };

    const closeLoading = () => {
        setLoadingOpen(false);
    }

    return (<div>
        <Header screenName="직업/직장" skipAvailable={memberInfo.workPlaceType && memberInfo.jobName ? true : false} nextFn={() =>{navigate("/income");}} />
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">직업 / 직장</div>
            <div className={descriptionSmallText}>직업 / 직장을 입력해 주세요</div>

            <div className="text-sm mt-40p">직장 분류</div>
            <div className="flex flex-row items-center mt-10p">
                <select onChange={(ev) => { setWorkPlaceType(ev.target.value) }} className={inputBoxNormalStyle + " w-334p mt-1"} defaultValue={memberInfo.workPlaceType} >
                    {
                        workTypeSelector.map((eachReligion: selectDataNumber) => {
                            return <option value={eachReligion.label}>{eachReligion.label}</option>
                        })
                    }
                </select>
            </div>
            <div className="text-sm mt-40p">직장명</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="search" className={inputBoxNormalStyle +" w-24"} placeholder="직장 검색" onChange={(ev) => getCompanyInfo(ev.target.value)} defaultValue={memberInfo.workPlaceName}/>
                <select onChange={(ev) => { setWorkPlaceName(ev.target.value) }} className={inputBoxNormalStyle + " w-60 ml-2"}>
                    {
                        companySelector.map((eachCompany: CompanyInfo) => {
                            let seperator = eachCompany.corpNo ?  " / " : "";
                            return <option value={eachCompany.name} className="text-xs">{eachCompany.name + seperator + eachCompany.type }</option>
                        })
                    }
                </select>
            </div>
            {/* <div className={ companySelector.length > 0 && companySelector[0].name=="검색결과가 없습니다"  ? "flex flex-row items-center mt-2 ml-1" : "hidden"  }> */}
            <div className="flex flex-row items-center mt-2 ml-1">
                <span className="text-sm w-20">직접입력 </span><input type="text" className={inputBoxNormalStyle + " w-72"} placeholder="직장 이름을 직접 입력해 주세요" onChange={(ev) => setWorkPlaceName(ev.target.value)} defaultValue={memberInfo.workPlaceName} />
            </div>
            
            <div className="text-sm mt-40p">직위</div>
            <div className="flex flex-row items-center mt-10p">
            <select onChange={(ev) => { setJobPosition(ev.target.value) }} className={inputBoxNormalStyle + " w-334p"} defaultValue={memberInfo.jobPosition}>
                    {
                        JobPositionSelector.map((eachPosition: selectDataNumber) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
                </select>
                {/* <input type="text" className={inputBoxNormalStyle +" w-334p"} placeholder="직위를 입력해 주세요" onChange={(ev) => setJobPosition(ev.target.value)} defaultValue={memberInfo.major}/> */}
            </div>

            <div className="text-sm mt-40p">직업명</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="text" className={inputBoxNormalStyle +" w-334p"} placeholder="직업을 알려주세요" onChange={(ev) => setJobName(ev.target.value)} defaultValue={memberInfo.jobName}/>
            </div>

            <div className="text-sm mt-40p">직업 상세</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="text" className={inputBoxNormalStyle +" w-334p"} placeholder="직업을 상세하게 알려주세요" onChange={(ev) => setJobDescription(ev.target.value)} defaultValue={memberInfo.jobDescription}/>
            </div>
            <div className="mt-8 flex flex-col mb-4">
                <div className="flex flex-row justify-between mb-15p">
                    <div className="text-sm">증명서 첨부</div>
                    <div className="text-sm text-col_font_gray">인증해서 매칭 확률을 높혀 보세요</div>
                </div>
                <label htmlFor="file-input" className="w-334p">
                    <div className={btnProofDocUpload} >
                        <img src={fileUpload} alt="직업 증명 파일 업로드 버튼"/><span className=" text-white text-base ml-1.5">증명서 첨부</span>
                    </div>
                </label>
                <div className="text-sm text-col_font_gray mt-2 ml-2">재직 증명서, 국민연금가입증명서, 건강보험납부증명서, 건강보험자격득실확인서, 회사 명함</div>
                <div className="text-sm text-col_font_gray ml-2 mt-3 ">증명서 발급(건강보험관련): </div>
                <a href="https://www.nhis.or.kr/nhis/index.do" target='_blank'>
                <span className="text-sm text-col_font_gray ml-2 underline">https://www.nhis.or.kr/nhis/index.do </span></a>
                
                <div className="text-sm text-col_font_gray ml-2 mt-3">증명서 발급(국민연금가입증명서): </div>
                <a href="https://minwon.nps.or.kr/jsppage/service/personal/inquiry/npsJoin_list.jsp" target='_blank'>
                <span className="text-sm text-col_font_gray ml-2 underline">https://minwon.nps.or.kr/jsppage/service/personal/inquiry/npsJoin_list.jsp</span></a>
                <input type="file" id="file-input" className="" onChange={handleUploadDoc} multiple hidden />

                <div className={uploadfileNames.length > 0  ? "text-sm text-col_font_gray mt-6" : "hidden"}>* 첨부된 파일  ({uploadfileNames.length}개) </div>
                {
                    uploadfileNames.map((eachFileName:string)=>{
                        return <div className="text-sm text-col_font_gray">{eachFileName}</div>
                    })
                }
            </div>
            <NextBtn  btnTitle="다음으로" ableCondtidion={workPlaceType && jobName ? true : false} nextScreen="income" dataHandlingFn={goToJob} />
        </div>
        <SimpleModal title="업로드 에러" message={modalMessage} btn1="확인" type="simple" closeFire={() => {setOpen(false)}} open={open} />
        <Loading open={loadingOpen} closeFire={() => {setLoadingOpen(false)}} />
    </div>);
}


export default Job;