import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import Header from "./common/header";
import { btnContinueStyle1, btnContinueStyle2, btnNomalStyle1, btnNomalStyle2, inputBoxNormalStyle, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import { birthYears, months, days, selectData, academySelector, selectDataNumber, maleSiblingSelector, femaleSiblingSelector, siblingOrderSelector, JobPositionSelector } from "../../common/dataType";
import checkGray from '../../img/check_gray.png';
import checkPink from '../../img/check_pink.png';
import circleX from '../../img/circle_x.png';
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";
import Loading from "./common/loading";

const Introduction = () => {
    const [introduction, setIntroduction] = useState("");
    const [fatherSchool, setFatherSchool] = useState("");
    
    const [loadingOpen, setLoadingOpen] = useState(false);
    const memberInfo = useContext(MemberContext);
    let navigate = useNavigate();

    useEffect(() => {
        console.log(" fatherPassedAway >> ", introduction);
        setIntroduction(memberInfo.introduction);
    }, [])


    const goToFavoriteType = async () => {
        setLoadingOpen(true);
        let serverUploadResult = {ok:false, type:"none"};
        let dataToInput = {
            id:memberInfo.id,
            introduction:introduction
        }
        let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", dataToInput);
        setLoadingOpen(false);
        if(dbUpdate.data.ok){
            memberInfo.introduction = dbUpdate.data.member.introduction;
            serverUploadResult.ok = true;
            serverUploadResult.type = "db";
        }
        return serverUploadResult;
    };

    return (<div>
        <Header screenName="자기 소개" skipAvailable={true} nextFn={()=>{navigate("/favoriteType")}} />
        <div className="ml-5 mr-5 mt-30p w-334p mb-55p">
            <div className="text-xl">자기소개</div>
            <div className="text-sm text-col_font_gray mt-5p">자기소개를 잘 작성하셔야 매칭률이 높아집니다</div>
            <textarea className="pl-1 border h-60 border-t-0 border-l-0 border-r-0 border-b-col_font_gray_light w-334p mb-20 mt-20p" placeholder="자신에 대해 자유롭게 알려주세요" onChange={(ev) => setIntroduction(ev.target.value)} />
            <NextBtn btnTitle="다음으로" ableCondtidion={true} nextScreen="favoriteType" dataHandlingFn={goToFavoriteType} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>
    </div>);
}

export default Introduction;