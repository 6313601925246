import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { academySelector, CompanyInfo, SchoolInfo, selectDataNumber, workTypeSelector, selectDataCode, JobPositionSelector } from "../../common/dataType";
import MemberContext from "../context/memberContext";
import { btnContinueStyle2, btnNomalStyle2, btnProofDocUpload, descriptionSmallText, inputBoxNormalStyle, nextBtnStyle, smallBtnNormalStyle, smallBtnSelectedStyle } from "../../common/commonStyle";
import Header from "./common/header";
import { SimpleModal } from "./common/modal";
import fileUpload from '../../img/file_upload_symbol.png';
import Loading from "./common/loading";
import NextBtn from "./common/nextBtn";

const Income = () => {

    const [annualSalary, setAnnualSalary] = useState<number>();
    const [cash, setCash] = useState<number>();
    const [realEstate, setRealEstage] = useState<number>();
    const [incomeFiles, setIncomeFiles] = useState<Array<any>>([]);
    const [assetFiles, setAssetFiles] = useState<Array<any>>([]);
    const [uploadIncomefileNames, setUploadIncomeFileNames] = useState<string[]>([]);
    const [uploadAssetfileNames, setUploadAssetFileNames] = useState<string[]>([]);
    const [open, setOpen] = useState(false);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [fileUploadStatusFail, setFileUploadStatusFail] = useState("");

    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    const reg = /[^\w\sㄱ-힣]|[\_]/g;

    useEffect(() => {
        if (memberInfo.id == 0) {
            navigate('/login');
        } else {
            setAnnualSalary(memberInfo.yearEarning);
            setCash(memberInfo.cash);
            setRealEstage(memberInfo.realEstate);
            setUploadIncomeFileNames(memberInfo.incomeProofDocsName);
            setUploadAssetFileNames(memberInfo.assetProofDocsName);
        }
    }, [])

    useEffect(() => {
        console.log(" annualSalary >> ", annualSalary);
        console.log(" cash >> ", cash);
        console.log(" realEstate >> ", realEstate);
    }, []);

    const inputDataToDB = async (dataToInput :any) => {
        let result = {ok:false, error:""};
        try{
            let dbUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", dataToInput);
            result = {ok:true, error:""};
            console.log(" >>> dbUpdate ", dbUpdate);
        }catch(ex){
            console.log(" >>> catch ", ex+"");
            result = {ok:false, error: ex+""};
        }finally{
            console.log(" >>> finally ");
            setLoadingOpen(false);
        };
        return result;
    };

    const handlingAfterDBUpdate = () => {

        memberInfo.yearEarning = annualSalary ? annualSalary : 0;
        memberInfo.cash = cash?cash : 0;
        memberInfo.realEstate = realEstate? realEstate:0;
    }

    const handlingAfterS3Update = () => {
        memberInfo.incomeProofDocsName = uploadIncomefileNames;
        memberInfo.assetProofDocsName = uploadAssetfileNames;
    }

    const uploadFileToS3 = async (files : any[], destinationS3:string) => {
        let formData = new FormData();
        let fileNames = [];
        let lastModifieds = "";
        for (let i = 0; i < files.length; i++) {
            formData.append("files", files[i]);
            let fileName = "proof/"+destinationS3+"/" + files[i].lastModified + "_" + memberInfo.id + "_" +files[i].name;  // DB에 들어가는 FileName
            let trimedFileName = fileName.replace(/ /g, "");
            fileNames.push(trimedFileName);
            lastModifieds = lastModifieds + files[i].lastModified + "_" + memberInfo.id + "_" + files[i].name+"#";
        }
        formData.append("filePath", "proof/"+destinationS3+"/");
        formData.append("lastModifieds", lastModifieds);
        let s3Result = await axios.post(common.SERVER_URL + "/common/filesUpload", formData);
        return {status: s3Result.status, fileNames:fileNames};
    };

    const updateToServer = async () =>{
        let serverUploadResult = {ok:false, type:"none"};
        setLoadingOpen(true);
        if (annualSalary != 0) {
            let incomeInfoToUpload = {
                id: memberInfo.id,
                yearEarning: annualSalary,
                cash: cash,
                realEstate: realEstate,
            }
         
            if(incomeFiles.length > 0) {
                let result = await uploadFileToS3(incomeFiles, "income");
                if(result.status == 201){
                    let incomeInfoToUploadWithFile = {...incomeInfoToUpload, incomeProofUrl: result.fileNames, isIncomeProofed: true}
                    incomeInfoToUpload = incomeInfoToUploadWithFile;
                }else{
                    setFileUploadStatusFail("income");
                }
            }
            
            if(assetFiles.length > 0){
                let result = await uploadFileToS3(assetFiles, "asset");
                if(result.status == 201){
                    let incomeInfoToUploadWithFile = {...incomeInfoToUpload, assetProofUrl: result.fileNames, isAssetProofed:true}
                    incomeInfoToUpload = incomeInfoToUploadWithFile;
                }else{
                    setFileUploadStatusFail("asset");
                }
            }

            let dbResult = await inputDataToDB(incomeInfoToUpload);
            
            if (dbResult.ok) {
                handlingAfterDBUpdate();
                serverUploadResult.ok = true;
                if(incomeFiles.length > 0 || assetFiles.length > 0){
                    serverUploadResult.type="file";
                    handlingAfterS3Update();
                }else{
                    serverUploadResult.type="db";
                }
            }else {
                serverUploadResult.ok = false;
                setModalMessage("소득 / 자산 정보 입력 오류");
                setOpen(true);
            }
            
            if (fileUploadStatusFail == "income") {
                setModalMessage("소득 증명 파일 업로드 실패");
                setOpen(true);
            } else if (fileUploadStatusFail == "asset") {
                setModalMessage("자산 증명 파일 업로드 실패");
                setOpen(true);
            }
        } else {
            setModalMessage("소득금액은 필수 정보입니다.");
            setOpen(true);
        }
        return serverUploadResult;
    }

    const goToParent = async () => {
        let updateResult = await updateToServer();
        return updateResult;
    }

    const handleIncomeUploadDoc = (ev: any) => {
        let docs: Array<any> = ev.target.files;
        console.log(" docs income >> ", docs);
        setIncomeFiles(docs);
        let urlDocs: Array<string> = [];
        let fnames: Array<string> = [];
        for (let i = 0; i < docs.length; i++) {
            urlDocs.push(URL.createObjectURL(docs[i]));
            fnames.push(docs[i].name.replaceAll(" ",""));
        }
        setUploadIncomeFileNames(fnames);
    }

    const handleAssetUploadDoc = (ev: any) => {
        let docs: Array<any> = ev.target.files;
        console.log(" docs asset >> ", docs);
        setAssetFiles(docs);
        let urlDocs: Array<string> = [];
        let fnames: Array<string> = [];
        for (let i = 0; i < docs.length; i++) {
            urlDocs.push(URL.createObjectURL(docs[i]));
            fnames.push(docs[i].name.replaceAll(" ",""));
        }
        setUploadAssetFileNames(fnames);
    }


    return (<div>
        <Header screenName="소득/자산" skipAvailable={memberInfo.yearEarning !=0 ? true : false} nextFn={() =>{navigate("/parent");}}/>
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">소득 정보</div>
            <div className="text-sm mt-40p">소득 정보</div>
            <div className="flex flex-row items-center mt-10p">
                <span className="mr-3 w-12">연봉: </span>
                <input type="text" className={inputBoxNormalStyle + " w-64"} placeholder="ex) 8500" onChange={(ev) => setAnnualSalary(Number(ev.target.value))} defaultValue={memberInfo.yearEarning == 0 ? undefined : memberInfo.yearEarning} />
                <span className="ml-3 w-12">만원</span>
            </div>
            
            <div className="mt-8 flex flex-col mb-4">
                <div className="flex flex-row justify-between mb-15p">
                    <div className="text-sm">증명서 첨부</div>
                    <div className="text-sm text-col_font_gray">인증해서 매칭 확률을 높혀 보세요</div>
                </div>
                <label htmlFor="file-input" className="w-334p">
                    <div className={btnProofDocUpload} >
                        <img src={fileUpload} alt="소득 증명서 파일 업로드 버튼"/><span className=" text-white text-base ml-1.5">증명서 첨부</span>
                    </div>
                </label>
                <div className="text-sm text-col_font_gray mt-2 ml-2">소득금액증명원(근로자, 사업자), 근로소득원청징수영수증, 월급명세서 및 연봉계약서 등</div>
                <div className="text-sm text-col_font_gray ml-2 mt-3 ">증명서 발급(소득금액증명원): </div>
                <a href="https://www.hometax.go.kr" target='_blank'>
                    <span className="text-sm text-col_font_gray ml-2 underline">{"홈택스 > 민원증명 > 소득금액증명"}</span></a>
                <input type="file" id="file-input" className="" onChange={handleIncomeUploadDoc} multiple hidden />
                <div className={uploadIncomefileNames.length > 0 ? "text-sm text-col_font_gray mt-6" : "hidden"}>* 첨부된 파일  ({uploadIncomefileNames.length}개) </div>
                {
                    uploadIncomefileNames.map((eachFileName: string) => {
                        return <div className="text-sm text-col_font_gray">{eachFileName}</div>
                    })
                }
            </div>
            <div className="text-xl mt-10">자산 정보</div>
            <div className="text-sm mt-40p">현금성 자산</div>
            <div className="flex flex-row items-center mt-10p">
            <input type="text" className={inputBoxNormalStyle + " w-334p"} placeholder="ex) 10000" onChange={(ev) => setCash(Number(ev.target.value))} defaultValue={memberInfo.cash == 0 ? undefined : memberInfo.cash} />
                <span className="ml-3 w-12">만원</span>
            </div>

            <div className="text-sm mt-40p">부동산 자산</div>
            <div className="flex flex-row items-center mt-10p">
                <input type="text" className={inputBoxNormalStyle + " w-334p"} placeholder="ex) 15000" onChange={(ev) => setRealEstage(Number(ev.target.value))} defaultValue={memberInfo.realEstate == 0 ? undefined : memberInfo.realEstate} />
                <span className="ml-3 w-12">만원</span>
            </div>
            <div className="mt-8 flex flex-col mb-4">
                <div className="flex flex-row justify-between mb-15p">
                    <div className="text-sm">증명서 첨부</div>
                    <div className="text-sm text-col_font_gray">인증해서 매칭 확률을 높혀 보세요</div>
                </div>
                <label htmlFor="file-input-asset" className="w-334p">
                    <div className={btnProofDocUpload} >
                        <img src={fileUpload} alt="자산 정보 증명서 파일 업로드 버튼" /><span className=" text-white text-base ml-1.5">증명서 첨부</span>
                    </div>
                </label>

                <div className="text-sm text-col_font_gray mt-2 ml-2">현금성 자산: 증권, 은행, 보험 잔고 캡쳐(본인명 포함)</div>
                <div className="text-sm text-col_font_gray ml-2">부동산: 등기부등본, 토지대장 </div>
                <div className="text-sm text-col_font_gray ml-2 mt-3 ">증명서 발급(부동산): 대법원 인터넷 등기소</div>
                <a href="http://www.iros.go.kr/PMainJ.jsp" target='_blank'>
                    <span className="text-sm text-col_font_gray ml-2 underline">http://www.iros.go.kr/PMainJ.jsp </span></a>
                <input type="file" id="file-input-asset" className="" onChange={handleAssetUploadDoc} multiple hidden />

                <div className={uploadAssetfileNames.length > 0 ? "text-sm text-col_font_gray mt-6" : "hidden"}>* 첨부된 파일  ({uploadAssetfileNames.length}개) </div>
                {
                    uploadAssetfileNames.map((eachFileName: string) => {
                        return <div className="text-sm text-col_font_gray">{eachFileName}</div>
                    })
                }
            </div>
            <NextBtn btnTitle="다음으로" ableCondtidion={annualSalary !=0 ? true : false} nextScreen="parent" dataHandlingFn={goToParent} />
        </div>
        <SimpleModal title="업로드 에러" message={modalMessage} btn1="확인" type="simple" closeFire={() => { setOpen(false) }} open={open} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default Income;