import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "./common/header";
import { inputBoxNormalStyle, smallBtnNormalStyle, smallBtnSelectedStyle, smBtnNorStyle, smBtnSelStyle } from "../../common/commonStyle";
import { birthYears, selectData, selectDataNumber, heights, religions } from "../../common/dataType";
import MemberContext from "../context/memberContext";
import { Hidden } from "@mui/material";
import NextBtn from "./common/nextBtn";
import common from "../../common/common";
import { ExtendsModal, SimpleModal } from "./common/modal";
import Loading from "./common/loading";


const FavoriteWeight = () => {
    const [apperance, setApperance] = useState<number>(0);
    const [condition, setCondition] = useState<number>(0);
    const [personality, setPersonality] = useState<number>(0);
    const [age, setAge] = useState<number>(0);
    const [family, setFamily] = useState<number>(0);
    const [total, setTotal] = useState<number>(0);
    const [open, setOpen] = useState(false);
    const [openExtModal, setOpenExtModal] = useState(false);
    const [modalMessage, setModalMessage] = useState("");
    const [modalMessage2, setModalMessage2] = useState("");
    const [modalTitle, setModalTitle] = useState("");

    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    const goToFavoriteWeight = async () => {
        setLoadingOpen(true);
        let serverUploadResult = {ok:false, type:"none"};
        // let preference = await axios.get(common.SERVER_URL+"/member/preference/"+memberInfo.id);
        console.log(" preferenceId >> ", memberInfo.preferenceId); 
        let dataToInput = {
            id:memberInfo.preferenceId,
            preferenceWeight:[apperance, condition, personality, age, family],
        }
      
        console.log(" dataToInput >> ", dataToInput);
         
        // 100보다 크거나 작으면 팝업
        if(total > 100){
            setModalTitle("가중치합 오류");
            setModalMessage("가중치 합이 100%보다 큽니다.");
            setOpen(true);
            setLoadingOpen(false);
            return;
        }else if(total < 100){
            setModalTitle("가중치합 오류");
            setModalMessage("가중치 합이 100%보다 작습니다.");
            setOpen(true);
            setLoadingOpen(false);
            return;
        }

        let dbUpdate = await axios.post(common.SERVER_URL + "/member/preference", dataToInput);
        setLoadingOpen(false);
        console.log(" dbUpdate >> ", dbUpdate); 
        memberInfo.preferenceWeight = dataToInput.preferenceWeight;

        if(dbUpdate.data.ok){
            serverUploadResult.ok = true;
            serverUploadResult.type = "db";
            setModalTitle("축하합니다!!");
            setModalMessage(`모든 매칭 준비가 완료되었습니다. 
            최대한 빠른시간안에 회원님이 좋아하실 만한 분을 매칭해드리겠습니다!`);
            setOpen(true);
        }
        return serverUploadResult;
    }
    
    useEffect(() => {
        let tot = apperance + condition + personality + age + family;
        setTotal(tot);
    },[apperance, condition, personality, age, family]);

    useEffect(()=>{
        if(memberInfo.preferenceWeight.length > 0){
            setApperance(memberInfo.preferenceWeight[0]);
            setCondition(memberInfo.preferenceWeight[1]);
            setPersonality(memberInfo.preferenceWeight[2]);
            setAge(memberInfo.preferenceWeight[3]);
            setFamily(memberInfo.preferenceWeight[4]);
        }
    },[])
    
    const moveToRecommendList = () => {
        navigate('/recommendList');
    }
    return (<div>
        <Header screenName="선호 가중치" skipAvailable={true} nextFn={() => { navigate('/matching') }} />
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">선호 가중치</div>
            <div className="text-sm text-col_font_gray mt-5p">합이 100%가 되도록 입력해 주세요</div> 
            <div className="text-sm text-col_font_gray mt-1"> 매칭 시 입력하신 선호 가중치가 고려됩니다</div>
            <div className="flex flex-row items-center mt-5">
                <div className="w-32">외모 / 신체적 매력</div>
                <input type="number" className={inputBoxNormalStyle + " w-20 ml-8"} placeholder="" onChange={(ev) => setApperance(Number(ev.target.value))} defaultValue={memberInfo.preferenceWeight.length == 0 ? undefined: memberInfo.preferenceWeight[0]} />
                <span className="ml-4">%</span>
            </div>
            <div className="flex w-125p items-center justify-center mt-2"> + </div>

            <div className="flex flex-row items-center mt-2">
                <div className="w-32">사회/ 경제적 조건</div>
                <input type="number" className={inputBoxNormalStyle + " w-20 ml-8"} placeholder="" onChange={(ev) => setCondition(Number(ev.target.value))} defaultValue={memberInfo.preferenceWeight.length == 0 ? undefined: memberInfo.preferenceWeight[1]} />
                <span className="ml-4">%</span>
            </div>
            <div className="flex w-125p items-center justify-center mt-2"> + </div>

            <div className="flex flex-row items-center mt-2">
                <div className="w-32 flex justify-center">성격</div>
                <input type="number" className={inputBoxNormalStyle + " w-20 ml-8"} placeholder="" onChange={(ev) => setPersonality(Number(ev.target.value))} defaultValue={memberInfo.preferenceWeight.length == 0 ? undefined: memberInfo.preferenceWeight[2]} />
                <span className="ml-4">%</span>
            </div>
            <div className="flex w-125p items-center justify-center mt-2"> + </div>

            <div className="flex flex-row items-center mt-2">
                <div className="w-32 flex justify-center">나이</div>
                <input type="number" className={inputBoxNormalStyle + " w-20 ml-8"} placeholder="" onChange={(ev) => setAge(Number(ev.target.value))} defaultValue={memberInfo.preferenceWeight.length == 0 ? undefined: memberInfo.preferenceWeight[3]} />
                <span className="ml-4">%</span>
            </div>
            <div className="flex w-125p items-center justify-center mt-2"> + </div>

            <div className="flex flex-row items-center mt-2">
                <div className="w-32 flex justify-center">가정 환경</div>
                <input type="number" className={inputBoxNormalStyle + " w-20 ml-8"} placeholder="" onChange={(ev) => setFamily(Number(ev.target.value))} defaultValue={memberInfo.preferenceWeight.length == 0 ? undefined: memberInfo.preferenceWeight[4]} />
                <span className="ml-4">%</span>
            </div>

            <div className="flex flex-row items-center mt-7 mb-7">
                <div className="w-32 flex justify-center">총합</div>
                <input type="number" disabled={true} className={inputBoxNormalStyle + " w-20 ml-8 pl-2"} value={total} />
                <span className="ml-4">%</span>
            </div>
       
            <NextBtn btnTitle="다음으로" ableCondtidion={true} nextScreen="favoriteWeight" dataHandlingFn={goToFavoriteWeight} />
            <SimpleModal title={modalTitle} message={modalMessage} btn1="확인" type="simple" closeFire={() => {setOpen(false);moveToRecommendList();}} open={open} />
            <ExtendsModal title={modalTitle} message1={modalMessage} message2={modalMessage2} btn1="확인" type="extends" btn2="매칭리스트로 이동" closeFire={() => {setOpenExtModal(false)}} open={openExtModal} btn2fn={moveToRecommendList} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>

    </div>);
}

export default FavoriteWeight;