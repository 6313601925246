export interface LoginInfo {
    email:string;
    pass:string;
}

export interface selectData {
    value:string,
    label:string,
};

export interface selectDataNumber {
    value:number,
    label:string,
};

export interface selectDataCode {
  value:number,
  label:string,
  code:string,
};

export interface modalData {
    open:boolean,
    title:string,
    message:string,
    btn1:string,
    btn2?:string,
    type:string, // simple or not, ...
    btn2fn?: () => void,
    closeFire: () => void,
}

export interface modalDataEx {
  open:boolean,
  title:string,
  message1:string,
  message2?:string,
  btn1:string,
  btn2?:string,
  type:string, // simple or not, ...
  btn2fn?: () => void,
  closeFire: () => void,
}


export interface HeaderProps {
  screenName:string,
  skipAvailable: boolean,
  nextFn?: () => void,
}

export interface FooterProps{
  current:number,
}

export interface NextBtnProps{
  btnTitle:string,
  ableCondtidion:boolean,
  nextScreen:string,
  dataHandlingFn: () => void,
}

export interface SchoolInfo {
  name:string,
  gubun:string,
  type:string,
}

export interface CompanyInfo {
  corpNo: string,
  name: string,
  avgSalary?: number,
  avgWorkingTerm?: number,
  avgEmployeeCnt?: number,
  type?: string,
}

export interface Parent {
  lastEducation?: string,
  schoolName?: string,
  job?: string,
  jobPosition?: string,
  isPassedAway?: boolean,
  isRetired?: boolean,
}

// export class Parent {
//   lastEducation: string;
//   schoolName: string;
//   job: string;
//   jobPosition: string
//   isPassedAway: boolean,
//   isRetired: boolean,
// }

export interface Member {
  id?:number;
  name: string;
  sex: string;
  mobile: string;
  email: string;
  birth: string;
  pass: string;
  address: string;
  bcode: string;
  religion: string;
  faithfullness: number;
  height: number;
  weight: number;
  profileImgUrls: string[];
  motionPicUrls: string[];
  siblingOrder: string;
  introduction: string;
  father: Parent;
  mother: Parent;
  parentAsset: number;
  lastEducation: string;
  lastSchoolName: string;
  major: string;
  secondSchoolName: string;
  academyProofUrl: string[];
  isAcademyProofed: boolean;
  workPlaceName: string;
  workPlaceType: string;
  jobPosition: string;
  jobName: string;
  jobDescription: string;
  jobProofUrl: string[];
  isJobProofed: boolean;
  yearEarning: number;
  cash: number;
  realEstate: number;
  realEstateDes: string;
  incomeProofUrl: string[];
  isIncomeProofed: boolean;
  assetProofUrl: string[];
  isAssetProofed: boolean;
  marriageStatusType: number;
  marriageProofUrl: string[];
  isMarriageProofed: boolean;
  attractivenessByRecords: number;
  attractivenessByReview: number;
  marketingAgree: boolean;
  plainPass:string;
}

export interface Preference {
  id?:number;
  ageMin: number;
  ageMax: number;
  heightMin: number;
  heightMax: number;
  bodyType:string[];
  religion:string[];
  marriageStatus:string[];
  accademy:string[];
  preferenceWeight:string[];
  memberId?:number;
  member?:Member;
}


export interface Matching {
  id?:number;
  dueDate: Date;
  isExpired: boolean;
  manId: number;
  womanId: number;
  isManAccepted: boolean;
  isWomanAccepted: boolean;
  manAcceptedTime: Date;
  womanAcceptedTime: Date;
  isManPaid: boolean;
  isWomanPaid: boolean;
  manPaidTime: Date;
  womanPaidTime: Date;
  isSendingContact: boolean;
  man:Member;
  woman:Member;
}

export interface MemberDetailProps{
  member : Member;
  matching : Matching;
}


export interface LoadingProps{
  title?:string,
  open:boolean,
  closeFire: () => void,
}

export const birthYears = [
    { value: '0000', label: '생년'},
    { value: '2003', label: '2003년'},
    { value: '2002', label: '2002년'},
    { value: '2001', label: '2001년'},
    { value: '2000', label: '2000년'},
    { value: '1999', label: '1999년'},
    { value: '1998', label: '1998년'},
    { value: '1997', label: '1997년'},
    { value: '1996', label: '1996년'},
    { value: '1995', label: '1995년'},
    { value: '1994', label: '1994년'},
    { value: '1993', label: '1993년'},
    { value: '1992', label: '1992년'},
    { value: '1991', label: '1991년'},
    { value: '1990', label: '1990년'},
    { value: '1989', label: '1989년'},
    { value: '1988', label: '1988년'},
    { value: '1987', label: '1987년'},
    { value: '1986', label: '1986년'},
    { value: '1985', label: '1985년'},
    { value: '1984', label: '1984년'},
    { value: '1983', label: '1983년'},
    { value: '1982', label: '1982년'},
    { value: '1981', label: '1981년'},
    { value: '1980', label: '1980년'},
    { value: '1979', label: '1979년'},
    { value: '1978', label: '1978년'},
    { value: '1977', label: '1977년'},
    { value: '1976', label: '1976년'},
    { value: '1975', label: '1975년'},
    { value: '1974', label: '1974년'},
    { value: '1973', label: '1973년'},
    { value: '1972', label: '1972년'},
    { value: '1971', label: '1971년'},
    { value: '1970', label: '1970년'},
    { value: '1969', label: '1969년'},
    { value: '1968', label: '1968년'},
    { value: '1967', label: '1967년'},
    { value: '1966', label: '1966년'},
    { value: '1965', label: '1965년'},
    { value: '1964', label: '1964년'},
    { value: '1963', label: '1963년'},
    { value: '1962', label: '1962년'},
    { value: '1961', label: '1961년'},
    { value: '1960', label: '1960년'},
    { value: '1959', label: '1959년'},
    { value: '1958', label: '1958년'},
    { value: '1957', label: '1957년'},
  ];

  export const months = [
    { value: '00', label: '태어난 달'},
    { value: '01', label: '1월'},
    { value: '02', label: '2월'},
    { value: '03', label: '3월'},
    { value: '04', label: '4월'},
    { value: '05', label: '5월'},
    { value: '06', label: '6월'},
    { value: '07', label: '7월'},
    { value: '08', label: '8월'},
    { value: '09', label: '9월'},
    { value: '10', label: '10월'},
    { value: '11', label: '11월'},
    { value: '12', label: '12월'},
  ];

  export const days = [
    { value: '00', label: '태어난 일'},
    { value: '01', label: '1일'},
    { value: '02', label: '2일'},
    { value: '03', label: '3일'},
    { value: '04', label: '4일'},
    { value: '05', label: '5일'},
    { value: '06', label: '6일'},
    { value: '07', label: '7일'},
    { value: '08', label: '8일'},
    { value: '09', label: '9일'},
    { value: '10', label: '10일'},
    { value: '11', label: '11일'},
    { value: '12', label: '12일'},
    { value: '13', label: '13일'},
    { value: '14', label: '14일'},
    { value: '15', label: '15일'},
    { value: '16', label: '16일'},
    { value: '17', label: '17일'},
    { value: '18', label: '18일'},
    { value: '19', label: '19일'},
    { value: '20', label: '20일'},
    { value: '21', label: '21일'},
    { value: '22', label: '22일'},
    { value: '23', label: '23일'},
    { value: '24', label: '24일'},
    { value: '25', label: '25일'},
    { value: '26', label: '26일'},
    { value: '27', label: '27일'},
    { value: '28', label: '28일'},
    { value: '29', label: '29일'},
    { value: '30', label: '30일'},
    { value: '31', label: '31일'},
  ];

export const religions = [
    { value: '0', label: '종교를 선택해 주세요'},
    { value: '1', label: '개신교'},
    { value: '2', label: '천주교'},
    { value: '3', label: '불교'},
    { value: '4', label: '유대교'},
    { value: '5', label: '이슬람교'},
    { value: '6', label: '힌두교'},
    { value: '7', label: '기타종교'},
    { value: '10', label: '무교(종교없음)'},
];

export const maleSiblingSelector = [
  { value: '0', label: '남자형제 수'},
  { value: '1', label: '1남'},
  { value: '2', label: '2남'},
  { value: '3', label: '3남'},
  { value: '4', label: '4남'},
  { value: '5', label: '5남'},
  { value: '6', label: '6남'},
  { value: '7', label: '7남'},
];

export const femaleSiblingSelector = [
  { value: '0', label: '여자형제 수'},
  { value: '1', label: '1녀'},
  { value: '2', label: '2녀'},
  { value: '3', label: '3녀'},
  { value: '4', label: '4녀'},
  { value: '5', label: '5녀'},
  { value: '6', label: '6녀'},
  { value: '7', label: '7녀'},
];

export const siblingOrderSelector = [
  { value: '0', label: '몇째인지 선택'},
  { value: '1', label: '1째'},
  { value: '2', label: '2째'},
  { value: '3', label: '3째'},
  { value: '4', label: '4째'},
  { value: '5', label: '5째'},
  { value: '6', label: '6째'},
  { value: '7', label: '7째'},
];


export const heights = [
    { value: 0, label: '키 선택'},
    { value: 143, label: '140~145 cm'},
    { value: 146, label: '146~147 cm'},
    { value: 148, label: '148~149 cm'},
    { value: 150, label: '150 cm'},
    { value: 151, label: '151 cm'},
    { value: 152, label: '152 cm'},
    { value: 153, label: '153 cm'},
    { value: 154, label: '154 cm'},
    { value: 155, label: '155 cm'},
    { value: 156, label: '156 cm'},
    { value: 157, label: '157 cm'},
    { value: 158, label: '158 cm'},
    { value: 159, label: '159 cm'},
    { value: 160, label: '160 cm'},
    { value: 161, label: '161 cm'},
    { value: 162, label: '162 cm'},
    { value: 163, label: '163 cm'},
    { value: 164, label: '164 cm'},
    { value: 165, label: '165 cm'},
    { value: 166, label: '166 cm'},
    { value: 167, label: '167 cm'},
    { value: 168, label: '168 cm'},
    { value: 169, label: '169 cm'},
    { value: 170, label: '170 cm'},
    { value: 171, label: '171 cm'},
    { value: 172, label: '172 cm'},
    { value: 173, label: '173 cm'},
    { value: 174, label: '174 cm'},
    { value: 175, label: '175 cm'},
    { value: 176, label: '176 cm'},
    { value: 177, label: '177 cm'},
    { value: 178, label: '178 cm'},
    { value: 179, label: '179 cm'},
    { value: 180, label: '180 cm'},
    { value: 181, label: '181 cm'},
    { value: 182, label: '182 cm'},
    { value: 183, label: '183 cm'},
    { value: 184, label: '184 cm'},
    { value: 185, label: '185 cm'},
    { value: 186, label: '186 cm'},
    { value: 187, label: '187 cm'},
    { value: 188, label: '188 cm'},
    { value: 189, label: '189 cm'},
    { value: 190, label: '190 cm'},
    { value: 192, label: '191~192 cm'},
    { value: 194, label: '193~195 cm'},
    { value: 198, label: '196~200 cm'},
  ];

  export const academySelector = [
    { value: 0, label: '최종학력 선택'},
    { value: 1, label: '대학원(박사) 졸업'},
    { value: 2, label: '대학원(석사) 졸업'},
    { value: 3, label: '대학원 수료'},
    { value: 4, label: '대학원 재학(휴학, 중퇴)'},
    { value: 5, label: '대학교 졸업'},
    { value: 6, label: '대학교 재학(휴학, 중퇴)'},
    { value: 7, label: '전문대학 졸업'},
    { value: 8, label: '전문대학 재학(휴학, 중퇴)'},
    { value: 9, label: '고등학교 졸업'},
    { value: 10, label: '고등학교 재학(중퇴)'},
    { value: 11, label: '중학교 졸업'},
  ];

  export const workTypeSelector = [
    { value: 0, label: '직장분류선택'},
    { value: 1, label: '정부'},
    { value: 2, label: '공기업'},
    { value: 3, label: '학교'},
    { value: 4, label: '대기업(1000명 이상)'},
    { value: 5, label: '중견기업(300~999명)'},
    { value: 6, label: '중소기업(20~299명)'},
    { value: 7, label: '개인기업/자영업(20명 미만)'},
    { value: 8, label: '프리랜서'},
    { value: 9, label: '해당없음'},
  ];

  export const JobPositionSelector = [
    { value: 0, label: '직위 선택', code: 'na' },
    { value: 1, label: '회장', code: 'c1' },
    { value: 2, label: '사장', code: 'c2' },
    { value: 3, label: '대표이사', code: 'c3' },
    { value: 4, label: '부사장', code: 'c4' },
    { value: 5, label: '전무', code: 'c5' },
    { value: 6, label: '상무', code: 'c6' },
    { value: 7, label: '이사', code: 'c7' },
    { value: 8, label: '부장', code: 'c8' },
    { value: 9, label: '차장', code: 'c9' },
    { value: 10, label: '과장', code: 'c10' },
    { value: 11, label: '대리', code: 'c11' },
    { value: 12, label: '계장', code: 'c12' },
    { value: 13, label: '사원', code: 'c13' },
    { value: 20, label: '소장', code: 'a1' },
    { value: 21, label: '수석연구원', code: 'a2' },
    { value: 22, label: '책임연구원', code: 'a3' },
    { value: 23, label: '선임연구원', code: 'a4' },
    { value: 24, label: '주임연구원', code: 'a5' },
    { value: 25, label: '연구원', code: 'a6' },
    { value: 30, label: '1급, 별정직', code: 'p1' },
    { value: 31, label: '2~3급', code: 'p2' },
    { value: 32, label: '4급', code: 'p3' },
    { value: 33, label: '5급', code: 'p4' },
    { value: 34, label: '6급', code: 'p5' },
    { value: 35, label: '7급', code: 'p6' },
    { value: 36, label: '8급', code: 'p7' },
    { value: 37, label: '9급', code: 'p8' },
    { value: 38, label: '10급', code: 'p9' },
    { value: 40, label: '기타', code: 'etc' },
  ];
  