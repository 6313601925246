import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import Header from "./common/header";
import { btnContinueStyle2, btnNomalStyle2, inputBoxNormalStyle, nextBtnStyle } from "../../common/commonStyle";
import { Modal } from "@mui/material";
import { SimpleModal } from "./common/modal";
import MemberContext from "../context/memberContext";
import NextBtn from "./common/nextBtn";
import { getUserInfo } from "./common/commonMethod";
import Loading from "./common/loading";

const SignUp = () => {
    const [email, setEmail] = useState(""); 
    const [pass, setPass] = useState(""); 
    const [repass, setRepass] = useState(""); 
    const [signupMessage, setSignupMessage] = useState(""); 
    const [signUpOK, setSignupOK] = useState(false); 
    const [openAlert, setOpenAlert] = useState(false); 
    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);

    const handleSignup = async () => {
        setLoadingOpen(true);
        let serverResult = {ok:false, type:'db'};
        let signupInfo = {
            id:memberInfo.id,
            email:email,
            pass:pass,
            recId:memberInfo.recId,
        }
        console.log(" signupInfo >> ", signupInfo);
        let result = await axios.post(common.SERVER_URL+"/member/signup", signupInfo);
        console.log("result signup >> ", result.data);
        setLoadingOpen(false);
        if(result.data.ok){
            serverResult.ok = true;
            serverResult.type = "db";
            memberInfo.email = email;
        }else{
            setSignupMessage("이미 가입된 메일입니다.")
            setOpenAlert(true);
        }
        return serverResult;
    }

    const goToAddress = () => {
        if(signUpOK){
            return handleSignup();
        }
    }

    useEffect(() => {
        if(pass == repass && email.includes("@") && email.length > 7){
            setSignupOK(true);
        }else{
            setSignupOK(false);
        }
    },[pass, repass])

    useEffect(()=>{
        setEmail(memberInfo.email);
    },[])

    return(<div>
        <Header screenName="계정 만들기" skipAvailable={memberInfo.email ? true : false} nextFn={() =>{navigate("/address");}}/>
        <div className="mt-30p pl-5 pr-5">
            <div className="text-xl">반갑습니다!</div>
            <div className="text-xl">이메일 입력으로 가입을 완료해주세요.</div>
            <div className="text-sm text-col_font_gray mt-10p">합리적인 사람들의 내 사람 찾기</div>
            <div className="text-sm mt-40p">이메일 (ID로 사용합니다.)</div>
            <input type="email" className={inputBoxNormalStyle +" w-334p"} placeholder="mydearyou@naver.com" onChange={(ev) => setEmail(ev.target.value)} defaultValue={email}/>
            <div className="text-sm mt-30p">비밀번호</div>
            <input type="password" className={inputBoxNormalStyle +" w-334p"} placeholder="" onChange={(ev) => setPass(ev.target.value)}/>
            <div className="text-sm mt-30p">비밀번호 확인</div>
            <input type="password" className={inputBoxNormalStyle +" w-334p mb-20"} placeholder="" onChange={(ev) => setRepass(ev.target.value)}/>
            {/* <div className={signUpOK ? nextBtnStyle+" mt-20" : btnNomalStyle2+" mt-20"} onClick={goToAddress}><span className=" text-white text-base">가입 완료</span></div> */}
            <NextBtn btnTitle={"가입완료"} ableCondtidion={signUpOK?true :false} nextScreen={"address"} dataHandlingFn={goToAddress} />
        </div> 
        <SimpleModal title="회원 가입" message={signupMessage} btn1="확인" type="simple" open={openAlert}  closeFire={() =>{setOpenAlert(false)}} />
        <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
    </div>);
}

export default SignUp;