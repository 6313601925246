import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Header from "./common/header";
import { inputBoxNormalStyle, smallBtnNormalStyle, smallBtnSelectedStyle, smBtnNorStyle, smBtnSelStyle } from "../../common/commonStyle";
import { birthYears, selectData, selectDataNumber, heights, religions } from "../../common/dataType";
import MemberContext from "../context/memberContext";
import { Hidden } from "@mui/material";
import NextBtn from "./common/nextBtn";
import common from "../../common/common";
import Loading from "./common/loading";


const FavoriteType = () => {
    const [favorAgeMin, setFavorAgeMin] = useState<number>(0);
    const [favorAgeMax, setFavorAgeMax] = useState<number>(0);
    const [favorHeightMin, setFavorHeightMin] = useState<number>(0);
    const [favorHeightMax, setFavorHeightMax] = useState<number>(0);
    const [favBodyViaSex, setFavBodyViaSex] = useState<string>("");
    const [favorBodyType, setFavorBodyType] = useState<string[]>([]);
    const [favorReligion, setFavorReligion] = useState<string[]>([]);
    const [favorMarriageStatus, setFavorMarriageStatus] = useState<string[]>([]);
    const [favorAccademy, setFavorAccademy] = useState<string[]>([]);
    const [loadingOpen, setLoadingOpen] = useState(false);
    const memberInfo = useContext(MemberContext);
    let navigate = useNavigate();
    let minBirthYears = [...birthYears];
    let maxBirthYears = [...birthYears];
    minBirthYears[0]= {value:"0000", label:"최소 생년"};
    maxBirthYears[0]= {value:"0000", label:"최대 생년"};

    let minHeight = [...heights];
    let maxHeight = [...heights];
    minHeight[0]= {value:0, label:"최소 키"};
    maxHeight[0]= {value:0, label:"최대 키"};

    const addOrRemoveIntoArray = (element:string, destArray:string[]) => {
        let resultArray : string[] = []; 
        if(destArray.includes(element)){
            resultArray = destArray.filter(each => {
                return each != element;
            })
        }else{
            destArray.push(element);
            resultArray = destArray;
        }
        return resultArray;
    };

    const goToFavoriteWeight = async () => {
        setLoadingOpen(true);
        let serverUploadResult = {ok:false, type:"none"};
        let dataToInput = {
            ageMin:favorAgeMin,
            ageMax:favorAgeMax,
            heightMin: favorHeightMin,
            heightMax: favorHeightMax,
            bodyType: favorBodyType,
            religion: favorReligion,
            marriageStatus: favorMarriageStatus,
            accademy: favorAccademy,
            memberId: memberInfo.id,
            id:undefined,
        }
        
        let member = await axios.get(common.SERVER_URL + "/member/"+memberInfo.id);
        setLoadingOpen(false);
        console.log(" member >>> ", member.data);
        if(member.data.member.preferenceId != null && member.data.member.preferenceId !=0 ){
            console.log(" member.data.member.preferenceId >>> ", member.data.member.preferenceId);
            dataToInput.id = member.data.member.preferenceId;
        }
        console.log(" dataToInput >>> ", dataToInput);
        let preferenceDBUpdate = await axios.post(common.SERVER_URL + "/member/preference", dataToInput);
        
        console.log(" preferenceDBUpdate >>> ", preferenceDBUpdate.data);

        let memberDBUpdate = await axios.post(common.SERVER_URL + "/member/updateMember", {id:memberInfo.id, preferenceId: preferenceDBUpdate.data.preference.id});
        console.log(" memberDBUpdate >>> ", memberDBUpdate.data);
        
        memberInfo.ageMin = favorAgeMin;
        memberInfo.ageMax = favorAgeMax;
        memberInfo.heightMin = favorHeightMin;
        memberInfo.heightMax = favorHeightMax;
        memberInfo.favBodyType = favorBodyType;
        memberInfo.favReligion = favorReligion;
        memberInfo.favAccademy = favorAccademy;
        memberInfo.favMarrigeStatus = favorMarriageStatus;
        memberInfo.preferenceId = memberDBUpdate.data.member.preferenceId;

        setLoadingOpen(false);
        if(preferenceDBUpdate.data.ok){
            serverUploadResult.ok = true;
            serverUploadResult.type = "db";
        }
        return serverUploadResult;
    }
    
    useEffect(() => {
        console.log("memberInfo.sex >> ", memberInfo.sex);
        if(memberInfo.sex == "m"){
            setFavBodyViaSex("볼륨있는몸매");
        }else{
            setFavBodyViaSex("근육질");
        }
    },[favorBodyType]);

    useEffect(()=>{
        setFavorBodyType(memberInfo.favBodyType);
        setFavorAccademy(memberInfo.favAccademy);
        setFavorReligion(memberInfo.favReligion);
        setFavorMarriageStatus(memberInfo.favMarrigeStatus);
        setFavorAgeMin(memberInfo.ageMin);
        setFavorAgeMax(memberInfo.ageMax);
        setFavorHeightMin(memberInfo.heightMin);
        setFavorHeightMax(memberInfo.heightMax);
    },[]);
    
    return (<div>
        <Header screenName="선호 타입" skipAvailable={true} nextFn={() => { navigate('/favoriteWeight') }} />
        <div className="ml-5 mr-5 mt-30p w-334p">
            <div className="text-xl">내 이상형</div>
            <div className="text-sm text-col_font_gray mt-5p">입력해 주신 선호타입을 고려하여 추천이 이루어집니다</div>
            <div className="text-sm mt-40p">선호 나이</div>
            <div className="flex flex-row items-center">
                <select onChange={(ev) => { setFavorAgeMin(Number(ev.target.value.replace('년',''))) }} className={inputBoxNormalStyle + " w-32"} defaultValue={memberInfo.ageMin+"년"}>
                    {
                     
                     minBirthYears.map((eachPosition: selectData) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
                </select>
                <span className="ml-3 mr-3 ">~</span>
                <select onChange={(ev) => { setFavorAgeMax(Number(ev.target.value.replace('년',''))) }} className={inputBoxNormalStyle + " w-32"} defaultValue={memberInfo.ageMax+"년"}>
                    {
                        maxBirthYears.map((eachPosition: selectData) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
                </select>
            </div>
            <div className="text-sm mt-40p">선호 신장</div>
            <div className="flex flex-row items-center">
                <select onChange={(ev) => { setFavorHeightMin(Number(ev.target.value.replace('cm',''))) }} className={inputBoxNormalStyle + " w-32"} defaultValue={memberInfo.heightMin+" cm"}>
                    {
                     
                     minHeight.map((eachPosition: selectDataNumber) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
                </select>
                <span className="ml-3 mr-3 ">~</span>
                <select onChange={(ev) => { setFavorHeightMax(Number(ev.target.value.replace('cm',''))) }} className={inputBoxNormalStyle + " w-32"} defaultValue={memberInfo.heightMax+" cm"}>
                    {
                        maxHeight.map((eachPosition: selectDataNumber) => {
                            return <option value={eachPosition.label} className="text-xs">{eachPosition.label}</option>
                        })
                    }
                </select>
            </div>
            <div className="text-sm mt-40p">선호 체형(복수 선택 가능)</div>
            <div className="flex flex-col mt-10p">
                <div className="flex flex-row items-center">
                    <button className={favorBodyType.includes('마른') ? smBtnNorStyle : smBtnSelStyle}
                        onClick={(ev) => setFavorBodyType(addOrRemoveIntoArray("마른", [...favorBodyType]))}
                    ><span className={favorBodyType.includes("마른") ? "text-col_main_pink" : "text-col_button_gray_text"}>마른</span></button>

                    <button className={favorBodyType.includes('날씬') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorBodyType(addOrRemoveIntoArray("날씬", [...favorBodyType]))}
                    ><span className={favorBodyType.includes("날씬") ? "text-col_main_pink" : "text-col_button_gray_text"}>날씬</span></button>

                    <button className={favorBodyType.includes('보통') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorBodyType(addOrRemoveIntoArray("보통", [...favorBodyType]))}
                    ><span className={favorBodyType.includes("보통") ? "text-col_main_pink" : "text-col_button_gray_text"}>보통</span></button>

                    <button className={favorBodyType.includes('통통') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorBodyType(addOrRemoveIntoArray("통통", [...favorBodyType]))}
                    ><span className={favorBodyType.includes("통통") ? "text-col_main_pink" : "text-col_button_gray_text"}>통통</span></button>
                </div>
                <div className={memberInfo.sex ? "flex flex-row items-center mt-10p" : "hidden"}>
                    <button className={favorBodyType.includes(favBodyViaSex) ? smBtnNorStyle : smBtnSelStyle}
                        onClick={(ev) => setFavorBodyType(addOrRemoveIntoArray(favBodyViaSex, [...favorBodyType]))}
                    ><span className={favorBodyType.includes(favBodyViaSex) ? "text-col_main_pink text-sm" : "text-col_button_gray_text text-sm"}>{favBodyViaSex}</span></button>
                </div>
            </div>

            <div className="text-sm mt-40p">종교(복수 선택 가능)</div>
            <div className="flex flex-col mt-10p">
                <div className="flex flex-row items-center">
                    <button className={favorReligion.includes('기독교') ? smBtnNorStyle : smBtnSelStyle }
                        onClick={(ev) => setFavorReligion(addOrRemoveIntoArray("기독교", [...favorReligion]))}
                    ><span className={favorReligion.includes("기독교") ? "text-col_main_pink" : "text-col_button_gray_text"}>기독교</span></button>

                    <button className={favorReligion.includes('천주교') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorReligion(addOrRemoveIntoArray("천주교", [...favorReligion]))}
                    ><span className={favorReligion.includes("천주교") ? "text-col_main_pink" : "text-col_button_gray_text"}>천주교</span></button>

                    <button className={favorReligion.includes('불교') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorReligion(addOrRemoveIntoArray("불교", [...favorReligion]))}
                    ><span className={favorReligion.includes("불교") ? "text-col_main_pink" : "text-col_button_gray_text"}>불교</span></button>

                    <button className={favorReligion.includes('무교') ? smBtnNorStyle + " ml-2": smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorReligion(addOrRemoveIntoArray("무교", [...favorReligion]))}
                    ><span className={favorReligion.includes("무교") ? "text-col_main_pink" : "text-col_button_gray_text"}>무교</span></button>
                </div>
                {/* <div className={"flex flex-row items-center mt-10p"}>
                    <button className={favorReligion.includes('무관') ? smBtnNorStyle : smBtnSelStyle}
                        onClick={(ev) => setFavorReligion(addOrRemoveIntoArray("무관", [...favorReligion]))}
                    ><span className={favorReligion.includes("무관") ? "text-col_main_pink" : "text-col_button_gray_text"}>무관</span></button>
                </div> */}
            </div>

            <div className="text-sm mt-40p">학력(복수 선택 가능)</div>
            <div className="flex flex-col mt-10p">
                <div className="flex flex-row items-center">
                    <button className={favorAccademy.includes('전문학사') ? smBtnNorStyle : smBtnSelStyle }
                        onClick={(ev) => setFavorAccademy(addOrRemoveIntoArray("전문학사", [...favorAccademy]))}
                    ><span className={favorAccademy.includes("전문학사") ? "text-col_main_pink" : "text-col_button_gray_text"}>전문학사</span></button>

                    <button className={favorAccademy.includes('학사') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorAccademy(addOrRemoveIntoArray("학사", [...favorAccademy]))}
                    ><span className={favorAccademy.includes("학사") ? "text-col_main_pink" : "text-col_button_gray_text"}>학사</span></button>

                    <button className={favorAccademy.includes('석/박사') ? smBtnNorStyle + " ml-2" : smBtnSelStyle + " ml-2"}
                        onClick={(ev) => setFavorAccademy(addOrRemoveIntoArray("석/박사", [...favorAccademy]))}
                    ><span className={favorAccademy.includes("석/박사") ? "text-col_main_pink" : "text-col_button_gray_text"}>석/박사</span></button>

                </div>
                {/* <div className={"flex flex-row items-center mt-10p"}>
                    <button className={favorAccademy.includes('무관') ? smBtnNorStyle : smBtnSelStyle}
                        onClick={(ev) => setFavorAccademy(addOrRemoveIntoArray("무관", [...favorAccademy]))}
                    ><span className={favorAccademy.includes("무관") ? "text-col_main_pink" : "text-col_button_gray_text"}>무관</span></button>
                </div> */}
            </div>

            <div className="text-sm mt-40p">결혼 여부(복수 선택 가능)</div>
            <div className="flex flex-col mt-10p mb-7">
                <div className="flex flex-row items-center">
                    <button className={favorMarriageStatus.includes('미혼') ? smBtnNorStyle : smBtnSelStyle }
                        onClick={(ev) => setFavorMarriageStatus(addOrRemoveIntoArray("미혼", [...favorMarriageStatus]))}
                    ><span className={favorMarriageStatus.includes("미혼") ? "text-col_main_pink" : "text-col_button_gray_text"}>미혼</span></button>

                    <button className={favorMarriageStatus.includes('재혼(자녀 없음)') ? smBtnNorStyle + " ml-2 w-24" : smBtnSelStyle + " ml-2 w-24"}
                        onClick={(ev) => setFavorMarriageStatus(addOrRemoveIntoArray("재혼(자녀 없음)", [...favorMarriageStatus]))}
                    ><span className={favorMarriageStatus.includes("재혼(자녀 없음)") ? "text-col_main_pink text-sm" : "text-col_button_gray_text text-sm"}>재혼(자녀 없음)</span></button>

                    <button className={favorMarriageStatus.includes('재혼(자녀 있음)') ? smBtnNorStyle + " ml-2 w-24" : smBtnSelStyle + " ml-2 w-24"}
                        onClick={(ev) => setFavorMarriageStatus(addOrRemoveIntoArray("재혼(자녀 있음)", [...favorMarriageStatus]))}
                    ><span className={favorMarriageStatus.includes("재혼(자녀 있음)") ? "text-col_main_pink text-sm" : "text-col_button_gray_text text-sm"}>재혼(자녀 있음)</span></button>

                </div>
                {/* <div className={"flex flex-row items-center mt-10p"}>
                    <button className={favorMarriageStatus.includes('무관') ? smBtnNorStyle : smBtnSelStyle}
                        onClick={(ev) => setFavorMarriageStatus(addOrRemoveIntoArray("무관", [...favorMarriageStatus]))}
                    ><span className={favorMarriageStatus.includes("무관") ? "text-col_main_pink" : "text-col_button_gray_text"}>무관</span></button>
                </div> */}
            </div>

            <NextBtn btnTitle="다음으로" ableCondtidion={true} nextScreen="favoriteWeight" dataHandlingFn={goToFavoriteWeight} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>

    </div>);
}

export default FavoriteType;