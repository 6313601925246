export const btnContinueStyle2 = "border rounded-full w-334p h-14 bg-col_main_pink bg-gradient-to-tl from-col_main_pink2 to-col_main_pink flex flex-col items-center justify-center";
export const btnContinueStyle1 = "border rounded-lg w-334p h-14 bg-col_main_pink bg-gradient-to-tl from-col_main_pink2 to-col_main_pink flex flex-col items-center justify-center";
export const btnNomalStyle1 = "border rounded-lg w-334p h-14 border-col_button_gray bg-col_back_gray flex flex-col items-center justify-center";
export const btnNomalStyle2 = "rounded-full w-334p h-14 bg-col_font_gray_dark flex flex-col items-center justify-center";
export const btnNomalStyle3 = "rounded-full w-334p h-14 border border-col_font_gray_dark flex flex-row items-center";
export const smallBtnNormalStyle = "border border-col_button_gray w-162p h-50p rounded-lg";
export const smallBtnSelectedStyle = "border border-col_main_pink w-162p h-50p rounded-lg";
export const inputBoxNormalStyle = "pl-1 border h-10 border-t-0 border-l-0 border-r-0 border-b-col_font_gray_light";
export const descriptionSmallText = "text-sm text-col_font_gray mt-10p";

export const btnProofDocUpload = "border rounded-full w-334p h-14 bg-black flex flex-row items-center justify-center";
export const nextBtnStyle = "border rounded-full w-334p h-14 bg-col_main_pink hover:bg-col_main_pink2 focus:ring focus:ring-col_main_pink flex flex-col items-center justify-center mr-5";

export const smBtnNorStyle = "border border-col_main_pink w-20 h-40p rounded-lg";
export const smBtnSelStyle = "border border-col_button_gray w-20 h-40p rounded-lg";