import React, {createContext} from 'react';
import { Parent } from '../../common/dataType';


export interface memberCtx {
    id?:number,
    email?:string,
    name?:string,
    mobile?:string,
    marketingAgree?:boolean,
    birth?:string,
    sex?:string,
    address?:string,
    religion?:string,
    faithfullness?:number,
    profileImgUrls?:string[],
    motionPicUrls?:string[],
    height?:number,
    weight?:number,
    marriageStatusType:number,
    marriageProofDocsName?:string[],
    lastEducation?:string,
    lastSchoolName?:string,
    major?:string,
    schoolProofDocsName?:string[],
    workPlaceType?:string,
    workPlaceName?:string,
    jobName?:string,
    jobDescription?:string,
    jobPosition?:string,
    jobProofDocsName?:string[],
    yearEarning?:number |undefined,
    cash?:number |undefined,
    realEstate?:number |undefined,
    realEstateDes?:string,
    incomeProofDocsName?:string[],
    assetProofDocsName?:string[],
    father:Parent,
    mother:Parent,
    siblingOrder:string,
    parentAsset:number,
    ageMin:number,
    ageMax:number,
    heightMin:number,
    heightMax:number,
    favBodyType:string[],
    favReligion:string[],
    favMarrigeStatus:string[],
    favAccademy:string[],
    preferenceWeight:number[],
    introduction:string,
    preferenceId:number,
    plainPass:string,
    recId:string,
}

const MemberContext = createContext({
    id:0,
    email:"",
    name:"",
    mobile:"",
    marketingAgree:false,
    birth:"",
    sex:"",
    address:"",
    religion:"",
    faithfullness:0,
    height:0,
    weight:0,
    profileImgUrls:Array<string>(),
    motionPicUrls:Array<string>(),
    marriageStatusType:0,
    marriageProofDocsName:new Array<string>(),
    lastEducation:"",
    lastSchoolName:"",
    major:"",
    schoolProofDocsName:new Array<string>(),
    workPlaceType:"",
    workPlaceName:"",
    jobName:"",
    jobDescription:"",
    jobPosition:"",
    jobProofDocsName:new Array<string>(),
    yearEarning:0,
    cash:0,
    realEstate:0,
    realEstateDes:"",
    incomeProofDocsName:new Array<string>(),
    assetProofDocsName:new Array<string>(),
    father:{lastEducation:"", schoolName:"", job:"", jobPosition:"", isPassedAway:false, isRetired:false},
    mother:{lastEducation:"", schoolName:"", job:"", jobPosition:"", isPassedAway:false, isRetired:false},
    sibling:"",      
    parentAsset:0,
    ageMin:0,
    ageMax:0,
    heightMin:0,
    heightMax:0,
    favBodyType:new Array<string>(),
    favReligion:new Array<string>(),
    favMarrigeStatus:new Array<string>(),
    favAccademy:new Array<string>(),
    preferenceWeight:Array<number>(),
    introduction:"",
    preferenceId:0,
    plainPass:"",
    recId:"",
})

export default MemberContext;