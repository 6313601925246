import React, { useState, useContext, useEffect, useCallback } from "react";
import axios from "axios";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import common from "../../common/common";
import { heights, LoginInfo, selectData, selectDataNumber } from "../../common/dataType";
import Header from "./common/header";
import { btnContinueStyle2, btnNomalStyle2, inputBoxNormalStyle, nextBtnStyle } from "../../common/commonStyle";
import MemberContext from "../context/memberContext";
import { getUserInfo } from "./common/commonMethod";
import NextBtn from "./common/nextBtn";
import Loading from "./common/loading";

const Apperance = () => {
    const [height, setHeight] = useState(160);
    const [weight, setWeight] = useState(-1);
    const [loadingOpen, setLoadingOpen] = useState(false);
    let navigate = useNavigate();
    const memberInfo = useContext(MemberContext);
    
    useEffect(() => {
        setHeight(memberInfo.height);
        setWeight(memberInfo.weight);
    }, []);

    const handleApperance = async () => {
     
        let serverUploadResult = {ok:false, type:"none"};
        if(memberInfo.id && height && weight){
            setLoadingOpen(true);
            let apperanceInfo = {
                id:memberInfo.id,
                height:height,
                weight:weight
            }
            let result = await axios.post(common.SERVER_URL+"/member/updateMember", apperanceInfo);
            setLoadingOpen(false);
            if(result.data.ok){
                serverUploadResult.ok = true;
                serverUploadResult.type = "db";
            }
        }else{

        }
        return serverUploadResult;
    }

    return(<div>
        <Header screenName="신체 정보" skipAvailable={memberInfo.height !=0 && memberInfo.weight !=0 ? true : false} nextFn={() =>{navigate("/photo");}} />
        <div className="ml-5 mr-5 mt-30p">
            <div className="text-xl mt-12">키</div>
            <div className="text-sm text-col_font_gray mt-10p">키를 입력해 주세요.</div>
            <select onChange={(ev) => { setHeight(Number(ev.target.value)) }} className={inputBoxNormalStyle + " w-334p mt-1"} defaultValue={memberInfo.height ? memberInfo.height : 0} >
                {
                    heights.map((eachReligion: selectDataNumber) => {
                        return <option value={eachReligion.value}>{eachReligion.label}</option>
                    })
                }
            </select>

            <div className="text-xl mt-12">체중</div>
            <div className="text-sm text-col_font_gray mt-10p">체중을 입력해 주세요. (체중은 공개되지 않습니다)</div>
            <div className="flex flex-row items-center mt-1 mb-20">
                <input type="number" className={inputBoxNormalStyle + " w-334p mr-2"} placeholder={memberInfo.weight ? memberInfo.weight+"" : "ex) 67kg"} onChange={(ev) => setWeight(Number(ev.target.value))} /><span>Kg</span>
            </div>
            <NextBtn btnTitle={"다음으로"} ableCondtidion={height && weight ? true: false} nextScreen={"photo"} dataHandlingFn={handleApperance} />
            <Loading open={loadingOpen} closeFire={() => { setLoadingOpen(false) }} />
        </div>
    </div>);
}

export default Apperance;